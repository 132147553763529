import { put, all, takeLatest } from 'redux-saga/effects';
import ApiService from '../../common/api/Api';
import { formsActions } from './FormsSlice';

function* createContact({ payload }) {

  yield put(formsActions.setLoading({ key: "createContact", newState: true }))

  const token = "Token token=BRnfdInR8XUhqeVPSFknMw";
  const endpoint = new URL(`https://yampi-inmobiliaria.myfreshworks.com/crm/sales/api/contacts`);

  yield fetch(`https://yampi-corss.herokuapp.com/https://yampi-inmobiliaria.myfreshworks.com/crm/sales/api/contacts`, {
    method: 'POST',
    body: JSON.stringify(payload?.data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': "*"
    },
    mode: 'cors'
  });

  payload.callback && payload.callback()

  yield put(formsActions.setLoading({ key: "createContact", newState: false }))
}

function* ActionWatcher() {
  yield takeLatest(formsActions.createContact, createContact)
}

export default function* FormsSaga() {
  yield all([
    ActionWatcher(),
  ]);
}
