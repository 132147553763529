import React from 'react';
import { ReactComponent as YampiPurple } from 'assets/icons/yampi-purple.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdBadge, faSignOutAlt, faUnlock, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { apiUrl } from 'common/config/Environments';

export const ProfileMenu = ({ onlogout, user }) => {

  return (
    <div className="profile-menu">
      <div className="dropdown">
        <div className="profile-menu-data">
          {user?.portrait ?
            <img
              crossOrigin="anonymous"
              src={apiUrl + user?.portrait?.filepath + user?.portrait?.filename}
              alt={`Avatar de ${user.profile.name}`}
            />
            :
            <YampiPurple />
          }
          <p>{user.profile.name + ' ' + user.profile.lastname.charAt(0)}</p>
        </div>
        <div className="dropdown-content">
          <a href='perfil'>
            <FontAwesomeIcon onClick={onlogout} icon={faUserCircle} />
            Perfil
          </a>
          <a onClick={onlogout}>
            <FontAwesomeIcon onClick={onlogout} icon={faSignOutAlt} />
            Cerrar sesión
          </a>
        </div>
      </div>
    </div>
  )
}