import React, { useEffect } from 'react';

import { FooterCopyright } from 'scenes/Public/components/FooterCopyright/FooterCopyright'
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'services/Auth/AuthSlice';

export const Downloads = () => {
  const { token } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(authActions.checkIsAuth())
  }, [])

  return (
    <div className="download-page">
      {!token && <header>
        <img src={require('assets/logo.png')} alt='logo' className="header__logo_yampi" />
      </header>}
      <div className="download-page-content">
        <div className="download-page-content-text">
          <h2>Gracias por utilizar Yampi, continua tu experiencia desde tu móvil</h2>
          <p>Descarga nuestra app</p>
          <br />
          <h3>Uno de nuestros asesores se pondrá en contacto contigo</h3>
        </div>
        <div className="download-page-content-actions">
          <a href='https://play.google.com/store/apps/details?id=co.yampi.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Disponible en Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/es-419_badge_web_generic.png' /></a>
          <a href="https://apps.apple.com/us/app/yampi/id1533897438?itsct=apps_box&amp;itscg=30200" ><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/es-MX?size=250x83&amp;releaseDate=1603670400&h=f564ebec18059cdb276641afb647f930" alt="Download on the App Store" style={{ borderRadius: '13px', width: '250px', height: '75px' }} /></a>
        </div>
      </div>
      <FooterCopyright />
    </div>
  )
}

