import React from 'react';
import { ReactComponent as Search } from  'assets/icons/Search.svg';
import { ReactComponent as Filter } from 'assets/icons/Filter.svg';
import { ReactComponent as Share } from 'assets/icons/Share.svg';

import { ReactComponent as Frame } from 'assets/icons/Frame.svg';
import { ReactComponent as Heart } from 'assets/icons/Heart.svg';
import { ReactComponent as Map } from 'assets/icons/Map.svg';

export const  Property = () => {

    return ( 
        <div className="property">
          <div className="container">
            <div className="body">
              <div className="body__main">
                <div className="body__search">
                  <div className="body__text"> 
                    <h3>Las mejores propiedades</h3>
                  </div>
                  <div className="body__group__filter">
                    <div className="body__city">
                    
                    </div>
                    <div className="body__icons">
                      <div className= " body__icon__row">
                        <Search className="svg-activate"/>
                      </div>
                      <div className= " body__icon__row">
                        <Filter className="svg-activate1"/>
                      </div>
                      <div className= " body__icon__row">
                        <Share className="svg-activate2"/>
                      </div>        
                    </div>
                  </div>       
                </div>
                <div className="body__list__properties">
                  <div className="body__list">
                    <div className="body__property">
                      <Frame/>
                    </div>
                    <div className="body__detail_property">
                      <div  className="body__row">
                        <div  className="body__name_property">
                          <h6>Propiedad la Clarita</h6>
                        </div>
                        <div>
                          <Heart/>
                        </div>
                      </div>
                      <div className="body__price">
                        <div className="body__detail__price">
                          <h6 >1'500.000</h6>
                        </div>
                      </div>
                      <div className="body__location">
                        <div className ="body__location-icon">
                          <Map/>
                        </div>
                        <div className="body__location">
                          <h4>Carrera 12 Sur #3a-36, Itagui.</h4>
                        </div>
                      </div>
                      <div className="body__list-amenities">
                        
                        <div className="content">
                          <div className="body__amenity">
                            <h4>6 cuartos</h4>
                          </div>
                          <div className="body__amenity">
                            <h4>2 baños</h4>
                          </div>
                          <div className="body__amenity">
                            <h4>1 cocina </h4> 
                          </div>
                        </div>







                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
    )
}