import { put, all, takeLatest } from 'redux-saga/effects';
import { phoneActions } from './PhoneSlice';
import ApiService from '../../common/api/Api';

function* registerPhone({ payload }) {
  try {
    yield put(phoneActions.onChangeStatus('loading'))
    const response = yield ApiService.post(`phone`, payload);
    yield put(phoneActions.onChangeStatus('success'))
  } catch (err) {
    yield put(phoneActions.onError('No se pudo registrar el teléfono'))
  }
}

function* updatePhone({ payload }) {
  try {
    yield put(phoneActions.onChangeStatus('loading'))
    const response = yield ApiService.put(`phone/${payload.entity_id}/user`, payload);
    yield put(phoneActions.onChangeStatus('success'))
  } catch (err) {
    yield put(phoneActions.onError('No se pudo actualizar el teléfono'))
  }
}

function* getPhone({ payload }) {
  try {
    yield put(phoneActions.onChangeStatus('loading'))
    const response = yield ApiService.post(`phone/${payload.entityId}/${payload.entityType}`);
    yield put(phoneActions.onChangeStatus('success'))
  } catch (err) {
    yield put(phoneActions.onError('No se pudo registrar el teléfono'))
  }
}

function* ActionWatcher() {
  yield takeLatest(phoneActions.registerPhone, registerPhone)
  yield takeLatest(phoneActions.updatePhone, updatePhone)
  yield takeLatest(phoneActions.getPhone, getPhone)
}

export default function* PhoneSaga() {
  yield all([
    ActionWatcher(),
  ]);
}


