import { put, all, takeLatest } from 'redux-saga/effects';
import { scheduleActions } from './ScheduleSlice';
import ApiService from '../../common/api/Api';
import { appActions } from '../App/AppSlice';

function* getAll() {
  try {
    yield put(scheduleActions.onChangeStatus('loading'))
    const response = yield ApiService.get(`/schedule`)
    yield put(scheduleActions.setSchedule(response))
    yield put(scheduleActions.onChangeStatus('success'))
  } catch (err) {
    yield put(scheduleActions.onError('No se pudo obtener el listado de eventos'))
  }
}

function* getTicketSchedule({ payload }) {
  yield put(scheduleActions.setScheduleTicket([]))
  try {
    yield put(scheduleActions.onChangeStatus('loading'))
    const response = yield ApiService.get(`/schedule/ticket/${payload.ticket_id}/${payload.type}`)//type 1: especialista, 0: usuarios    
    yield put(scheduleActions.setScheduleTicket(response.data))
    yield put(scheduleActions.onChangeStatus('success'))
  } catch (err) {
    yield put(scheduleActions.onError('No se pudo obtener el listado de eventos del ticket'))
  }
}

function* getScheduleByEntity({ payload }) {
  yield put(scheduleActions.setScheduleTicket([]))
  try {
    yield put(scheduleActions.onChangeStatus('loading'))
    const response = yield ApiService.get(`/schedule/${payload.entityName}/${payload.schedulableId}/${payload.type}`)//type 1: quien recibe, 0: quien envía 
    yield put(scheduleActions.setSCurrentSchedules(response.data))
    yield put(scheduleActions.onChangeStatus('success'))
  } catch (err) {
    yield put(scheduleActions.onError('No se pudo obtener el listado de eventos de la entidad'))
  }
}


function* acceptCitation({ payload }) {
  try {
    yield put(scheduleActions.onChangeStatus('loading'))
    yield ApiService.put(`schedule`, payload)
    yield put(scheduleActions.onChangeStatus('success'))
  } catch (err) {
    yield put(scheduleActions.onError('No se pudo aceptar la citación'))
  }
}

function* createSchedule({ payload }) {
  try {
    const response = yield ApiService.post('schedule', payload)
  } catch (err) {
    let erroObj = JSON.parse(err.message);
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: 'Ocurrio un error al agendar las visitas',
      feedbackIcon: ''
    }))
  }
}

function* ActionWatcher() {
  yield takeLatest(scheduleActions.getAll, getAll)
  yield takeLatest(scheduleActions.getTicketSchedule, getTicketSchedule)
  yield takeLatest(scheduleActions.acceptCitation, acceptCitation)
  yield takeLatest(scheduleActions.createSchedule, createSchedule)
  yield takeLatest(scheduleActions.getScheduleByEntity, getScheduleByEntity)
}

export default function* ScheduleSaga() {
  yield all([
    ActionWatcher(),
  ]);
}

