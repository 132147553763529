import React from 'react';
import { ReactComponent as Profile } from 'assets/icons/Profile.svg';
import { ReactComponent as Speciality } from 'assets/icons/Speciality.svg';
import { ReactComponent as Accounts } from 'assets/icons/Accounts.svg';

export const Steps = ({ currentStep }) => {
  const activateTab = (key) => {
    let t = ''
    if (currentStep === key) t = 'step-tab-activate'
    return t
  }

  return (
    <div className="step-tabs">
      <div className={`step-tab ${activateTab(1)}`}>
        <div className="step-tab-icon" >
          <Profile />
        </div>
        <h2>Tu perfil</h2>
      </div>
      <div className={`step-tab ${activateTab(2)}`}>
        <div className="step-tab-icon" >
          <Speciality />
        </div>
        <h2>Especialidades</h2>
      </div>
      <div className={`step-tab ${activateTab(3)}`}>
        <div className="step-tab-icon" >
          <Accounts />
        </div>
        <h2>Cuentas</h2>
      </div>
    </div>
  )
}