import { put, all, takeLatest } from 'redux-saga/effects';
import ApiService from '../../common/api/Api';
import { specialityActions } from './SpecialitySlice';
import { appActions } from '../App/AppSlice';

function* getSpecialities({ payload }) {
  yield put(specialityActions.onChangeStatus('loading'))
  try {
    const specialities = yield ApiService.get('/speciality')
    yield put(specialityActions.setSpecialities(specialities))
    yield put(specialityActions.onChangeStatus('success'))
  } catch (err) {
    yield put(specialityActions.onError(err.toString()))
  }
}

function* getSubSpecialities({ payload }) {
  yield put(specialityActions.onChangeStatus('loading'))
  try {
    const specialities = yield ApiService.get('/speciality/' + payload)
    yield put(specialityActions.setSubSpecialities(specialities))
    yield put(specialityActions.onChangeStatus('success'))
  } catch (err) {
    yield put(specialityActions.onError(err.toString()))
  }
}

function* registerSpecialities({ payload }) {
  yield put(specialityActions.onChangeStatus('loading'))

  yield put(specialityActions.setLoading({ key: 'registerSpecialities', newState: true }))
  let responses = []
  try {
    yield* payload?.specialities?.map(async function (item) {
      try {
        const response = await ApiService.post('speciality', item)
        responses.push(response)
      } catch (e) {
        return null;
      }
    })
    payload.callback()
    yield put(specialityActions.onChangeStatus('success'))
  } catch (err) {
    let erroObj = JSON.parse(err.message)

    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: erroObj.message == 'Element already exist' ? 'La especialidad ya se encuentra registrada' : 'Estamos teniendo problemas, reintenta mas tarde',
      feedbackIcon: ''
    }))

    yield put(specialityActions.setError({ key: 'registerSpecialities', newState: erroObj.message }))
  }
  yield put(specialityActions.setLoading({ key: 'registerSpecialities', newState: false }))
}

function* getUserSpecialities({ payload }) {
  yield put(specialityActions.onChangeStatus('loading'))
  try {
    const specialities = yield ApiService.get('/speciality/user/' + payload)
    yield put(specialityActions.setUserSpecialities(specialities))
    yield put(specialityActions.onChangeStatus('success'))
  } catch (err) {
    yield put(specialityActions.onError(err.toString()))
  }
}

function* getUserSpeciality({ payload }) {
  yield put(specialityActions.setLoading({ key: 'getUserSpeciality', newState: true }))

  try {
    const speciality = yield ApiService.get('speciality-data/' + payload)
    yield put(specialityActions.setSpecialityUser(speciality))

  } catch (err) {
    let erroObj = JSON.parse(err.message)

    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: 'Estamos teniendo problemas, reintenta mas tarde',
      feedbackIcon: ''
    }))

    yield put(specialityActions.setError({ key: 'getUserSpeciality', newState: erroObj.message }))
  }
  yield put(specialityActions.setLoading({ key: 'getUserSpeciality', newState: false }))
}

function* createSpeciality({ payload }) {

  yield put(specialityActions.setLoading({ key: 'createSpeciality', newState: true }))

  try {
    yield ApiService.post('speciality', payload?.speciality)
    payload.callback()

  } catch (err) {
    let erroObj = JSON.parse(err.message)

    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: erroObj.message == 'Element already exist' ? 'La especialidad ya se encuentra registrada' : 'Estamos teniendo problemas, reintenta mas tarde',
      feedbackIcon: ''
    }))

    yield put(specialityActions.setError({ key: 'createSpeciality', newState: erroObj.message }))
  }
  yield put(specialityActions.setLoading({ key: 'createSpeciality', newState: false }))
}

function* updateSpeciality({ payload }) {
  yield put(specialityActions.setLoading({ key: 'updateSpeciality', newState: true }))

  try {
    yield ApiService.put('speciality/' + payload.specialityId, payload.speciality)
    payload.callback()
  } catch (err) {
    let erroObj = JSON.parse(err.message)
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: erroObj?.message == 'Element already exist' ? 'La especialidad ya se encuentra registrada' : 'Estamos teniendo problemas, reintenta mas tarde',
      feedbackIcon: ''
    }))
    yield put(specialityActions.setError({ key: 'updateSpeciality', newState: erroObj?.message }))
  }
  yield put(specialityActions.setLoading({ key: 'updateSpeciality', newState: false }))
}

function* deleteSpeciality({ payload }) {
  yield put(specialityActions.setLoading({ key: 'deleteSpeciality', newState: true }))

  try {
    yield ApiService.delete('speciality/' + payload.specialityId)
    payload.callback()
  } catch (err) {
    let erroObj = JSON.parse(err.message)
    yield put(specialityActions.setError({ key: 'deleteSpeciality', newState: erroObj?.message }))
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: 'Estamos teniendo problemas, reintenta mas tarde',
      feedbackIcon: ''
    }))
  }
  yield put(specialityActions.setLoading({ key: 'deleteSpeciality', newState: false }))
}

function* ActionWatcher() {
  yield takeLatest(specialityActions.getSpecialities, getSpecialities)
  yield takeLatest(specialityActions.getSubSpecialities, getSubSpecialities)
  yield takeLatest(specialityActions.registerSpecialities, registerSpecialities)
  yield takeLatest(specialityActions.createSpeciality, createSpeciality)
  yield takeLatest(specialityActions.getUserSpecialities, getUserSpecialities)
  yield takeLatest(specialityActions.getUserSpeciality, getUserSpeciality)
  yield takeLatest(specialityActions.updateSpeciality, updateSpeciality)
  yield takeLatest(specialityActions.deleteSpeciality, deleteSpeciality)
}

export default function* SpecialitySaga() {
  yield all([
    ActionWatcher(),
  ]);
}
