import { put, all, takeLatest, call } from 'redux-saga/effects';
import { invitationActions } from './InvitationSlice';
import { contractActions } from '../Contract/ContractSlice';
import { appActions } from '../App/AppSlice';
import ApiService from '../../common/api/Api';

function* getAll() {
  try {
    yield put(invitationActions.onChangeStatus('loading'))
    const response = yield ApiService.get(`/invitation`)
    yield put(invitationActions.setInvitations(response.data))
    yield put(invitationActions.onChangeStatus('success'))
  } catch (err) {
    yield put(invitationActions.onError('No se pudo obtener el listado de invitaciones'))
  }
}

function* getInvitationsDescriptions({ payload }) {
  yield put(invitationActions.onChangeStatus('loading'))
  let descriptions = []
  try {
    yield* payload.map(async function (item) {
      try {
        const description = await ApiService.get('/content/' + item.invitable_id + '/ticket')
        descriptions.push({
          id: item.id,
          description: description.data[0]
        })
      } catch (e) {
        return null;
      }
    })
    yield put(invitationActions.setDescriptions(descriptions))
    yield put(invitationActions.onChangeStatus('success'))
  } catch (err) {
    yield put(invitationActions.onError(err.toString()))
  }
}

function* registerInvite({ payload }) {
  try {
    yield put(invitationActions.onChangeStatus('loading'))
    const response = yield ApiService.post(`/register/invite`, payload.body)

    if (response) {
      const debtor = yield put(invitationActions.sendInvitation({ contractId: payload.contractId, userId: response.id, invitation_type_id: payload.invitation_type_id }));
    }
    yield put(invitationActions.onChangeStatus('success'))
  } catch (err) {
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: err != null ? err.toString() : 'Estamos teniendo problemas, reintenta mas tarde',
      feedbackIcon: ''
    }))
    yield put(invitationActions.onError('No se pudo enviar la invitación'))
  }
}

function* sendInvitation({ payload }) {
  try {
    yield put(invitationActions.onChangeStatus('loading'))
    const response = yield ApiService.post(`/invitation/${payload.contractId}/contract`, { user_id: payload.userId, invitation_type_id: payload.invitation_type_id })
    yield put(invitationActions.onChangeStatus('success'))
  } catch (err) {
    yield put(invitationActions.onError('No se pudo enviar la invitación'))
  }
}

function* approve({ payload }) {

  yield put(invitationActions.setLoadinApprove(true))
  try {
    yield put(invitationActions.onChangeStatus('loading'))
    const response = yield ApiService.patch(`/invitation/approve/${payload.invitationId}`)
    if (response) {
      const resContrar = yield put(contractActions.finale({ contractId: payload.contractId }))
    }

    payload.callback && payload.callback()
    yield put(invitationActions.setLoadinApprove(false))
    yield put(invitationActions.onChangeStatus('success'))
  } catch (err) {
    const resContrar = yield put(contractActions.finale({ contractId: payload.contractId }))
    yield put(invitationActions.onError('No se pudo aprobar la invitación'))
  }
}

function* revoke({ payload }) {

  try {
    yield put(invitationActions.onChangeStatus('loading'))
    const response = yield ApiService.delete(`/invitation/${payload.invitationId}`)

    payload.callback && payload.callback()

    yield put(invitationActions.onChangeStatus('success'))
  } catch (err) {

    yield put(invitationActions.onError('No se pudo revocar la invitación'))
  }
  yield put(invitationActions.onChangeStatus('success'))
}

function* activateInvitation({ payload }) {
  try {
    yield put(invitationActions.onChangeStatus('loading'))
    yield ApiService.patch(`invitation/approve/` + payload)
    const response = yield ApiService.get(`/invitation`)
    yield put(invitationActions.setInvitations(response.data))
    yield put(invitationActions.onChangeStatus('success'))
  } catch (err) {
    yield put(invitationActions.onError('No se pudo obtener el listado de invitaciones'))
  }
}

function* ActionWatcher() {
  yield takeLatest(invitationActions.getAll, getAll)
  yield takeLatest(invitationActions.getInvitationsDescriptions, getInvitationsDescriptions)
  yield takeLatest(invitationActions.activateInvitation, activateInvitation)
  yield takeLatest(invitationActions.registerInvite, registerInvite)
  yield takeLatest(invitationActions.sendInvitation, sendInvitation)
  yield takeLatest(invitationActions.approve, approve)
  yield takeLatest(invitationActions.revoke, revoke)
}

export default function* InvitationSaga() {
  yield all([
    ActionWatcher(),
  ]);
}

