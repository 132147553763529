import React from 'react';
import { useDispatch } from 'react-redux'
import { useForm, useWatch } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUnlock, faEnvelope, faEnvelopeOpen, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { authActions } from 'services/Auth/AuthSlice';
import { useHistory } from "react-router-dom";
import { InputForm } from 'components/form/input/InputForm';

export const SignUpForm = () => {
  let history = useHistory()
  const dispatch = useDispatch()

  const { register, handleSubmit, errors, watch } = useForm()

  const onSubmit = data => {

    const userData = {
      "email": data.email,
      "password": data.password,
      "role_id": 5,
      "profile": {
        "name": data.userName,
        "lastname": data.lastName,
        "country_id": 2
      }
    }

    dispatch(authActions.signup(userData))
    history.push("/")
  }

  return (
    <div className="login_form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={errors.address ? "form-error-input" : ''}>
          <InputForm
            icon={faUser}
            name="userName"
            refHook={register({ required: 'Campo obligatorio' })}
            placeholder="Nombres"
            type="text"
          />
          {errors.userName && <span className="form-error-msg">{errors.userName.message}</span>}
        </div>

        <div className={errors.lastName ? "form-error-input" : ''}>
          <InputForm
            icon={faUserAlt}
            name="lastName"
            refHook={register({ required: 'Campo obligatorio' })}
            placeholder="Apellidos"
            type="text"
          />
          {errors.lastName && <span className="form-error-msg">{errors.lastName.message}</span>}
        </div>

        <div className={errors.email ? "form-error-input" : ''}>
          <InputForm
            icon={faEnvelope}
            name="email"
            refHook={register({ required: 'Campo obligatorio' })}
            placeholder="Correo"
            type="email"
          />
          {errors.email && <span className="form-error-msg">{errors.email.message}</span>}
        </div>

        <div className={errors.confirmEmail ? "form-error-input" : ''}>
          <InputForm
            icon={faEnvelopeOpen}
            name="confirmEmail"
            refHook={register({
              required: 'Campo obligatorio',
              validate: (value) => value === watch('email') || "Los correos no son iguales"
            })}
            placeholder="Confirma tu correo"
            type="email"
          />
          {errors.confirmEmail && <span className="form-error-msg">{errors.confirmEmail.message}</span>}
        </div>

        <div className={errors.password ? "form-error-input" : ''}>
          <InputForm
            icon={faUnlock}
            name="password"
            refHook={register({ required: 'Campo obligatorio' })}
            placeholder="Contraseña"
            type="password"
          />
          {errors.password && <span className="form-error-msg">{errors.password.message}</span>}
        </div>
        <div className="login_form__check_terms">
          <input type="checkbox" id="cbox1" name="cbox1" value={true} ref={register({ required: 'Los términos y condiciones son obligatorios' })} />
          <label>He leído y acepto los <a>Términos y condiciones</a> y las <a>Políticas de privacidad</a>.</label>
          {errors.cbox1 && <span style={{ marginTop: 35 }} className="form-error-msg">{errors.cbox1.message}</span>}
        </div>
        <button type="submit" >crear cuenta</button>
      </form>
    </div>
  )
}