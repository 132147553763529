import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './AuthActions';

const initialState = {
  isAuthenticated: null,
  status: null,
  errormsg: null,
  token: null,
  tokenExpo: null,
  currentUserData: null,
  confirmCode: null,
  role: null,
  loading: false,
  loadingGroup: {
    profile: false
  },
  changePassword: false
}

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login(state, { payload }) {
      state.loading = true;
    },
    signup(state, { payload }) {
      state.loading = true;
    },
    signupResponse(state, { payload }) {
      state.loading = false;
    },
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.loading = false;
      state.errormsg = payload;
    },
    setToken(state, { payload }) {
      state.token = payload;
      state.loading = false;
    },
    setTokenExpo(state, { payload }) {
      state.tokenExpo = payload;
    },
    setIsAuthenticated(state, { payload }) {
      state.isAuthenticated = payload;
    },
    setUserData(state, { payload }) {
      state.currentUserData = payload;
      state.isAuthenticated = true;
    },
    setRole(state, { payload }) {
      state.role = payload.role;
    },
    profileUpdate(state, { payload }) {
      state.loadingGroup.profile = true;
    },
    profileUpdateResponse(state, { payload }) {
      state.loadingGroup.profile = false;
    },
    changePassword(state, { payload }) {
      state.loading = true
    },
    changePasswordResponse(state, { payload }) {
      state.loading = false
    },
    setChangePassword(state, { payload }) {
      state.changePassword = payload
    },
    logout() {
      return initialState;
    }
  }
});

const authActions = { ...authSlice.actions, ...asyncActions }
const authReducer = authSlice.reducer

export { authActions, authReducer };