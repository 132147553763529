import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlock } from '@fortawesome/free-solid-svg-icons'

export const SearchInput = ({ refHook, name, placeholder, onChange, datalist, icon }) => {
  return (
    <div className="form-item">
      <FontAwesomeIcon icon={icon || faUnlock} />
      <input name={name}
        list={datalist}
        type="text"
        autoComplete="new-off"
        ref={refHook}
        placeholder={placeholder} onChange={(e) => onChange ? onChange(e) : ''} />
    </div>
  )
}