import React, { useEffect } from 'react';
import { Public } from 'scenes/Public/Public'
import { Private } from 'scenes/Private/Private'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from 'services/Auth/AuthSlice';

function App() {
  //Preguntar si se puede modificar en core
  const { token } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(authActions.checkIsAuth())
  }, [])

  return (
    <div className={token ? 'root-grid' : ''}>
      {!token ? <Public /> : <Private />}
    </div>
  );
}

export default App;
