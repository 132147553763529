import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas, faUniversity, faMoneyCheck, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux';
import { locationActions } from 'services/Location/LocationSlice';
import { bankActions } from 'services/Bank/BankSlice';
import { useForm } from 'react-hook-form';
import { InputForm } from 'components/form/input/InputForm';


export const Accounts = ({ next }) => {
  const [listAccount, setListAccount] = useState([]);
  const [listAccountPayload, setListAccountPayload] = useState([]);
  const [AccountName, setAccountName] = useState('');
  const [countryName, setCountryName] = useState('');
  const [accountTypeName, setAccountTypeName] = useState('');
  const { register, handleSubmit, errors, reset } = useForm();
  const dispatch = useDispatch()
  const { countries } = useSelector(state => state.location)
  const { banks, bankAccountTypes } = useSelector(state => state.bank)

  useEffect(() => {
    dispatch(locationActions.getCountries())
  }, [])

  const getCountryOnChange = e => {
    let q = e.target.value
    let index = e.target.selectedIndex;
    setCountryName(e.target[index].text)
    dispatch(bankActions.getBanks(q))
    dispatch(bankActions.getBanksAccountType(q))
  }

  const handleAccountChange = e => {
    let index = e.target.selectedIndex;
    setAccountName(e.target[index].text)
  }

  const handleAccountTypeChange = e => {
    let index = e.target.selectedIndex;
    setAccountTypeName(e.target[index].text)
  }

  const deleteAccount = (index) => {
    setListAccount(listAccount.filter((t, i) => i !== index))
    setListAccountPayload(listAccountPayload.filter((t, i) => i !== index))
  }

  const onSubmit = data => {
    let cuenta = { AccountName: AccountName, countryName: countryName, accountTypeName: accountTypeName }
    let accounts = listAccount
    let payload = listAccountPayload
    let cont = {
      "number": data.accountNumber,
      "bank_account_type": { "id": data.bankAccountType },
      "bank": { "id": data.banks }
    }
    accounts.push({ ...data, ...cuenta })
    payload.push(cont)
    setListAccount(accounts)
    setListAccountPayload(payload)
    reset()
  }

  const nextStep = () => {
    let data = {
      banks: listAccountPayload
    }
    next(data)
  }
  const getAccountTypeName = (type) => {
    switch (type) {
      case "savings":
        return 'Ahorro'
        break;
      case "checking":
        return 'De cheques'
        break;
      default:
        return type
    }
  }

  return (
    <div className="form-container form-speciality">
      <form onSubmit={handleSubmit(onSubmit)} className="form-container-wrapper">
        <div className="form-row">
          <div className={errors.countries ? "form-error-input" : ''}>
            <div className="form-item">
              <FontAwesomeIcon icon={faGlobeAmericas} />
              <select name="countries" defaultValue="" ref={register({ required: 'Campo obligatorio' })} onChange={getCountryOnChange} >
                <option value="" disabled>País</option>
                {countries?.map((item, key) =>
                  <option key={key} value={item.id}>{item.name}</option>
                )}
              </select>
            </div>
            {errors.countries && <span className="form-error-msg">{errors.countries.message}</span>}
          </div>
          <div className={errors.accountNumber ? "form-error-input" : ''}>
            <InputForm
              name="accountNumber"
              refHook={register({
                required: 'Campo obligatorio',
                minLength: { value: 10, message: "Número muy corto" },
              })}
              placeholder="Número de cuenta"
              type="number"
            />
            {errors.accountNumber && <span className="form-error-msg">{errors.accountNumber.message}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className={errors.banks ? "form-error-input" : ''}>
            <div className="form-item">
              <FontAwesomeIcon icon={faUniversity} />
              <select name="banks" defaultValue="" ref={register({ required: 'Campo obligatorio' })} disabled={banks.length === 0} onChange={handleAccountChange}>
                <option value="" disabled>Banco</option>
                {banks?.map((item, key) =>
                  <option key={key} value={item.id}>{item.name}</option>
                )}
              </select>
            </div>
            {errors.banks && <span className="form-error-msg">{errors.banks.message}</span>}
          </div>

          <div className={errors.foreignAccount ? "form-error-input" : ''}>
            <div className="form-item form-item-checkbox">
              <label>¿Es una cuenta extranjera?
              <input type="checkbox" placeholder="Cuenta extranjera" name="foreignAccount" ref={register} />
              </label>
            </div>
            {errors.foreignAccount && <span className="form-error-msg">{errors.foreignAccount.message}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className={errors.bankAccountType ? "form-error-input" : ''}>
            <div className="form-item">
              <FontAwesomeIcon icon={faMoneyCheck} />
              <select name="bankAccountType" defaultValue="" disabled={bankAccountTypes?.length === 0} ref={register({ required: 'Campo obligatorio' })} onChange={handleAccountTypeChange}>
                <option value="" disabled>Tipo de cuenta</option>
                {bankAccountTypes?.map((item, key) =>
                  <option key={key} value={item.id}>
                    {getAccountTypeName(item.name)}
                  </option>
                )}
              </select>
            </div>
            {errors.bankAccountType && <span className="form-error-msg">{errors.bankAccountType.message}</span>}
          </div>
          <div>
            <button type="submit">Agregar {listAccount.length > 0 && 'otra'} cuenta</button>
          </div>
        </div>

        {listAccount.length > 0 && <div className="list-specialities">
          {listAccount?.map((account, i) =>
            <div className="speciality-card" key={i}>
              <div className="speciality-card-data">
                <div className="speciality-card-category">
                  <p>{account.AccountName}</p>
                  <span>{account.countryName}</span>
                </div>

                <div className="speciality-card-category">
                  <p>{account.accountNumber}</p>
                  <span>{account.accountTypeName}</span>
                </div>

                <div className="speciality-card-description">{account.foreignAccount ? 'Extranjera' : ''}</div>
              </div>
              <FontAwesomeIcon icon={faMinusCircle} onClick={() => deleteAccount(i)} />
            </div>
          )}
        </div>}

        <div className="specialist-container-button">
          <button type="button" onClick={() => nextStep()} disabled={listAccount.length <= 0} >Terminar</button>
        </div>
      </form>
    </div>
  )
}