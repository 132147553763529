import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlock } from '@fortawesome/free-solid-svg-icons'

export const InputForm = ({ refHook, name, placeholder, type, icon }) => {
  return (
    <div className="form-item">
      <FontAwesomeIcon icon={icon || faUnlock} />
      <input name={name}
        placeholder={placeholder}
        type={type}
        ref={refHook} />
    </div>
  )
}