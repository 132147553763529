import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './TicketActions';

const initialState = {
  status: null,
  errormsg: null,
  ticketsAssociates: [],
  descriptions: null,
  feedbackShowing: {
    isShowing: false,
    feedbackTitle: '',
    feedbackDescription: '',
    feedbackIcon: ''
  },
  ticketProcess: null,
  tickets: [],
  ticketCart: [],
  ticket: {},
  loading: false,
  loadingTicket:{
    getTicket: false,
    getTicketCart: false,
    addTicketCost: false,
    acceptCost: false,
    getTicketProcess: false
  }
}

const ticketSlice = createSlice({
  name: "ticket",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setTicketsAssociates(state, { payload }) {
      state.ticketsAssociates = payload;
    },
    setDescriptions(state, { payload }) {
      state.descriptions = payload
    },
    setFeedback(state, { payload }) {
      /*       state.feedbackShowing = { ...state.feedbackShowing, ...payload }; */
    },
    setTickets(state, { payload }) {
      state.tickets = payload;
    },
    setTicket(state, { payload }) {
      state.ticket = payload;
    },
    setTicketCart(state, { payload }) {
      state.ticketCart = payload;
    },
    setTicketProcess(state, { payload }) {
      state.ticketProcess = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload
    },
    setLoadingTicket(state, { payload: { key, newState }  }) {
      state.loadingTicket = { ...state.loading, [key]: newState } 
    }
  }
});

const ticketActions = { ...ticketSlice.actions, ...asyncActions }
const ticketReducer = ticketSlice.reducer

export { ticketActions, ticketReducer };