export const ReferedTenantFormHtml = `<!-- <link href="https://d1gwclp1pmzk26.cloudfront.net/agile/forms/v1/agile-form.min.css" rel="stylesheet"> -->
<link href="https://doxhze3l6s7v9.cloudfront.net/app/static/agile/forms/agile-form.min.css" rel="stylesheet">
<form class="form-view theme2 form-tenant form-refered" id="agile-form" action="https://yampi.agilecrm.com/formsubmit" style="max-width:450px;" method="GET">
<fieldset>
<!-- Form Name -->
<div style="display: none; height: 0px; width: 0px;">
<input type="hidden" id="_agile_form_name" name="_agile_form_name" value="Formato Prospecto Inquilino">
<input type="hidden" id="_agile_domain" name="_agile_domain" value="yampi">
<input type="hidden" id="_agile_api" name="_agile_api" value="n1q7qmniukqotjtg2uvi2fenre">
<input type="hidden" id="_agile_redirect_url" name="_agile_redirect_url" value="https://app.yampi.co/descargas">
<input type="hidden" id="_agile_document_url" name="_agile_document_url" value="">
<input type="hidden" id="_agile_confirmation_msg" name="_agile_confirmation_msg" value="">
<input type="hidden" id="_agile_form_id_tags" name="tags" value="ASEGURAMIENTO">
<input type="hidden" id="_agile_form_id" name="_agile_form_id" value="5848136703606784">
</div>
<!-- Text input-->
<div class="agile-group required-control">
  <label class="agile-label" for="agilefield-6">Nombre <span class="agile-span-asterisk"> *</span></label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="agilefield-6" name="first_name" type="text" placeholder="* Nombres" class="agile-height-default" required="">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group required-control">
  <label class="agile-label" for="agilefield-12">Apellido<span class="agile-span-asterisk"> *</span></label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="agilefield-12" name="last_name" type="text" placeholder="* Apellidos" class="agile-height-default" required="">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group required-control">
  <label class="agile-label" for="agilefield-9">Correo electrónico<span class="agile-span-asterisk"> *</span></label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="agilefield-9" name="email" type="email" placeholder="* Correo electrónico" class="agile-height-default" required="">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group required-control">
  <label class="agile-label" for="agilefield-8">Celular<span class="agile-span-asterisk"> *</span></label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="agilefield-8" name="phone" type="text" placeholder="* Celular" class="agile-height-default" required="">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group required-control">
  <label class="agile-label" for="agilefield-4">Ciudad<span class="agile-span-asterisk"> *</span></label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="agilefield-4" name="city" type="text" placeholder="* Ciudad" class="agile-height-default" required="">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group required-control">
  <label class="agile-label" for="agilefield-13">Inmueble de interés<span class="agile-span-asterisk"> *</span></label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="agilefield-13" name="agilefield-13" type="text" placeholder="* Inmueble de interés" class="agile-height-default" required="">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group required-control" >
  <label class="agile-label" for="agilefield-14">Correo del agente inmobiliario<span class="agile-span-asterisk"> *</span></label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="agilefield-14" name="note" type="text" placeholder="* Correo del agente inmobiliario" class="agile-height-default" required="">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!--recaptcha aglignment-->
<!-- Button -->
<div class="agile-group">
  <label class="agile-label">&nbsp;</label>
  <div class="agile-field agile-button-field">
    <button type="submit" class="agile-button">registrar</button>
    <br><span id="agile-error-msg"></span>
  </div>
</div>
</fieldset>
</form>
<script type="text/javascript">
(function(a){var b=a.onload,p=true;isCaptcha=false;if(p){a.onload="function"!=typeof b?function(){try{_agile_load_form_fields()}catch(a){}}:function(){b();try{_agile_load_form_fields()}catch(a){}}};var formLen=document.forms.length;for(i=0;i<formLen;i++){if(document.forms.item(i).getAttribute("id")== "agile-form"){a.document.forms.item(i).onsubmit=function(a){a.preventDefault();try{_agile_synch_form_v5(this)}catch(b){this.submit()}}}}})(window);
</script>
`