import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { authActions } from 'services/Auth/AuthSlice';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';

export const Header = () => {
  let history = useHistory()
  const dispatch = useDispatch()
  const { currentUserData } = useSelector(state => state.auth)
  const onlogout = () => {
    dispatch(authActions.logout())
    history.push("/login")
  }

  return (
    <div className="header">
      <div className="header__logo">
        <a href={currentUserData ? '/' : '/registrate'} className="menu__list4__signup" >
          <img src={require('assets/logo.png')} alt='logo' className="header__logo_yampi" />
        </a>
      </div>

      {!currentUserData ?
        <ul className="menu">
          {/* <li className="menu__list1"><span className="menu__list1__property" >Inmuebles</span></li>
          <li className="menu_list2"><span className="menu__list2__function" >Como funciona?</span></li> */}
          <li className="menu_list3"><a className="menu__list3__support" >Soporte</a></li>
          <li className="menu_list4"><a href="/registrate/especialista" className="menu__list4__signup" >Regístrate</a></li>
        </ul>
        :
        <ul className="menu">
          <li className="menu__list1">
            <ProfileMenu onlogout={onlogout} user={currentUserData} />
          </li>
        </ul>}
    </div>
  )
}