import createAction from '../../common/utils/create-action';

const asyncActions = createAction({
  auth: [
    'socialLogin',
    'confirm',
    'resendCode',
    'recoveryPassword',
    'checkIsAuth',
    'fetchUser',
    'docidUpdate'
  ]
})
export default asyncActions