import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faUnlock, faVenusMars, faMapMarkerAlt, faPhoneAlt, faIdCard, faIdCardAlt, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux';
import { locationActions } from 'services/Location/LocationSlice';
import { SelectInput } from 'components/form/select/SelectInput';
import { SearchInput } from 'components/form/search/SearchInput';
import { useForm, Controller } from 'react-hook-form';
import { InputForm } from 'components/form/input/InputForm';
import { genreActions } from 'services/Genre/GenreSlice';
import { docIdTypeActions } from 'services/DocIdType/DocIdTypeSlice';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { datePickerToSpanish, formatDate } from 'common/utils/dates';

export const Profile = ({ next }) => {
  const [selectedCity, selectCity] = useState('');
  const { register, errors, handleSubmit, control } = useForm();
  const dispatch = useDispatch()
  const { currentUserData } = useSelector(state => state.auth)
  const { locations } = useSelector(state => state.location)
  const { genreList } = useSelector(state => state.genre)
  const { docIdTypeList } = useSelector(state => state.docIdType)

  useEffect(() => {
    dispatch(genreActions.getGenres())
    dispatch(docIdTypeActions.getDocIdType())
  }, [])

  const getLocationOnChange = e => {
    let q = e.target.value
    const query = {
      q: q,
      country_id: 2
    }
    if (q.length > 2)
      dispatch(locationActions.getLocation(query))

    if (e.target.name === 'city')
      selectCity(locations.filter(t => t.fullname === e.target.value))
  }

  const onSubmit = data => {
    let genre = { "genre_id": data.genre }
    let docId = {
      "docidable_id": currentUserData.id,
      "docid_type_id": data.docIdType,
      "code": data.docId,
      "issue_date": formatDate(data.issueDate),
      "issue_location": data.issueCity
    }
    let phone = {
      "country_id": 2,
      "phone_type_id": 1,
      "number": data.telefono,
      "entity_type": "user",
      "entity_id": currentUserData.id,
      "extension": "",
      "prefix": "+57"
    }
    let localization = {
      userId: currentUserData.id,
      body: {
        "location_id": selectedCity[0].id,
        "latitude": selectedCity[0].latitude,
        "longitude": selectedCity[0].longitude,
        "address_data": "user",
        "address": data.address
      }
    }
    let formatData = { genre: genre, docId: docId, phone: phone, localization: localization }
    next(formatData);
  }

  const getDocIdTypeName = (type) => {
    switch (type) {
      case "passport":
        return 'Pasaporte'
      case "driver_licence":
        return 'Licencia de conducción'
      case "citizen_document":
        return 'Cédula'
      case "foreign_document":
        return 'Cédula de extranjería'
      case "nit":
        return 'NIT'
      default:
        return type
    }
  }
  return (
    <div className="form-container">
      <form onSubmit={handleSubmit(onSubmit)} className="form-container-wrapper">
        <div className="form-row">
          <div className={errors.city ? "form-error-input" : ''}>
            <SearchInput
              onChange={getLocationOnChange}
              icon={faCity}
              datalist={"locations-list"}
              refHook={register({ required: 'Campo obligatorio' })}
              name="city"
              placeholder="Ciudad, Departamento"
            />
            <datalist id="locations-list">
              {locations.length > 0 && locations?.map((item, key) =>
                <option key={key} data-value={item}>{item.fullname}</option>
              )}
            </datalist>
            {errors.city && <span className="form-error-msg">{errors.city.message}</span>}
          </div>
          <div className={errors.address ? "form-error-input" : ''}>
            <InputForm
              icon={faMapMarkerAlt}
              name="address"
              refHook={register({ required: 'Campo obligatorio' })}
              placeholder="Dirección"
              type="text"
            />
            {errors.address && <span className="form-error-msg">{errors.address.message}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className={errors.genre ? "form-error-input" : ''}>
            <div className="form-item">
              <FontAwesomeIcon icon={faVenusMars} />
              <select name="genre" defaultValue="" ref={register({ required: 'Campo obligatorio' })}>
                <option value="" disabled>Sexo</option>
                {genreList?.map((item, key) =>
                  <option key={key} value={item.id}>{item.id !== 3 ? item.id === 2 ? 'Femenino' : 'Masculino' : 'Otro'}</option>
                )}
              </select>
            </div>
            {errors.genre && <span className="form-error-msg">{errors.genre.message}</span>}
          </div>
          <div className={errors.telefono ? "form-error-input" : ''}>
            <InputForm
              icon={faPhoneAlt}
              placeholder="Teléfono"
              name="telefono"
              type="tel"
              refHook={register({
                required: 'Campo obligatorio',
                maxLength: { value: 10, message: "Número muy Largo" },
                minLength: { value: 8, message: "Número muy corto" },
                pattern: {
                  value: /[0-9]+/i,
                  message: "Número no válido"
                }
              })}
            />
            {errors.telefono && <span className="form-error-msg">{errors.telefono.message}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className={errors.docIdType ? "form-error-input" : ''}>
            <div className="form-item">
              <FontAwesomeIcon icon={faIdCardAlt} />
              <select name="docIdType" defaultValue="" ref={register({ required: 'Campo obligatorio' })} >
                <option value="" disabled>Tipo de documento</option>
                {docIdTypeList?.map((item, key) =>
                  <option key={key} value={item.id}>
                    {getDocIdTypeName(item.name)}
                  </option>
                )}
              </select>
            </div>
            {errors.docIdType && <span className="form-error-msg">{errors.docIdType.message}</span>}
          </div>
          <div className={errors.docId ? "form-error-input" : ''}>
            <InputForm
              icon={faIdCard}
              name="docId"
              type="number"
              placeholder="Número de documento"
              refHook={register({ required: 'Campo obligatorio' })}
            />
            {errors.docId && <span className="form-error-msg">{errors.docId.message}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className={errors.issueCity ? "form-error-input" : ''}>
            <SearchInput
              onChange={getLocationOnChange}
              icon={faCity}
              datalist={"locations-list"}
              refHook={register({ required: 'Campo obligatorio' })}
              name="issueCity"
              placeholder="Lugar de expedición"
            />
            <datalist id="locations-list">
              {locations.length > 0 && locations?.map((item, key) =>
                <option key={key} value={item.fullname}>{item.fullname}</option>
              )}
            </datalist>
            {errors.issueCity && <span className="form-error-msg">{errors.issueCity.message}</span>}
          </div>
          <div className={errors.issueDate ? "form-error-input" : ''}>
            <div className="form-item">
              <FontAwesomeIcon icon={faCalendar} />
              <Controller
                control={control}
                name="issueDate"
                rules={{ required: 'Campo obligatorio' }}
                render={({ onChange, value }) => (
                  <DatePicker
                    onChange={onChange}
                    locale={datePickerToSpanish}
                    selected={value}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Fecha de expedición"
                    closeOnScroll={true}
                  />
                )}
              />
            </div>
            {errors.issueDate && <span className="form-error-msg">{errors.issueDate.message}</span>}
          </div>
        </div>
        <div className="specialist-container-button">
          <button type="submit" >Siguiente</button>
        </div>
      </form>
    </div>
  )
}