import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as YampiPurple } from 'assets/icons/yampi-purple.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTools, faUniversity, faUnlock, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { specialityActions } from 'services/Speciality/SpecialitySlice';
import { bankActions } from 'services/Bank/BankSlice';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { Speciality } from '../Specialist/components/Steps/Speciality/Speciality';
import { apiUrl } from 'common/config/Environments';

export const ProfileUser = () => {
  const dispatch = useDispatch()
  const [editSpeciality, setEditSpeciality] = useState(false);
  const [editAccount, setEditAccount] = useState(false);

  const { currentUserData } = useSelector(state => state.auth)
  const { userSpecialities } = useSelector(state => state.speciality)
  const { bankAccount } = useSelector(state => state.bank)

  useEffect(() => {
    if (currentUserData) {
      dispatch(specialityActions.getUserSpecialities(currentUserData.id))
      dispatch(bankActions.getBankAccount())
    }
  }, [currentUserData])

  const handleEditSpecialities = (data) => {
    //dispatch(specialityActions.registerSpecialities(data.specialities))
    dispatch(specialityActions.registerSpecialities({ specialities: data.specialities, callback: (function () { setEditSpeciality(!editSpeciality) }) }))
    dispatch(specialityActions.getUserSpecialities(currentUserData.id))
  }

  return (
    <div>
      {currentUserData ? <div className="profile-user">
        <div className="profile-user-header">
          <div className="profile-user-header-portrait">
            {currentUserData?.portrait ?
              <img
                crossOrigin="anonymous"
                src={apiUrl + currentUserData?.portrait?.filepath + currentUserData?.portrait?.filename}
                alt={`Avatar de ${currentUserData.profile.name}`}
              />
              :
              <YampiPurple />
            }
          </div>
          <div className="profile-user-header-data">
            <div className="profile-user-header-name">{`${currentUserData?.profile.name} ${currentUserData?.profile.lastname}`}</div>
            <div className="profile-user-header-email">
              <a href={`mailto:${currentUserData?.email}`}>{currentUserData?.email}</a>
               -{`${currentUserData?.phone[0]?.prefix || ''} ${currentUserData?.phone[0]?.number || ''}`}</div>
            <div className="profile-user-header-city">{`${currentUserData?.localization?.location?.fullname || ''}`}</div>
          </div>
        </div>
        <div className="profile-user-content">

          <div className="profile-user-content-item">
            <div className="profile-user-content-item-title">
              <FontAwesomeIcon icon={faTools} />
              <p>Especialidades</p>
              <div onClick={() => setEditSpeciality(!editSpeciality)}><FontAwesomeIcon icon={faEdit} /></div>
            </div>
            <div className="profile-user-content-item-data">
              {editSpeciality ?
                <Speciality next={handleEditSpecialities} />
                :
                userSpecialities?.data?.length > 0 ?
                  userSpecialities?.data.map((item, i) =>
                    <div key={i}>
                      <div>{item.speciality_type.name}</div>
                    </div>
                  ) :
                  <h1>No hay especialidades asignadas...</h1>
              }
            </div>
          </div>
          <div className="profile-user-content-item">
            <div className="profile-user-content-item-title">
              <FontAwesomeIcon icon={faUniversity} />
              <p>Cuentas bancarias</p>
              <div><FontAwesomeIcon icon={faEdit} /></div>
            </div>
            <div className="profile-user-content-item-data">
              {bankAccount?.length > 0 ?
                bankAccount?.map((item, i) =>
                  <div key={i}>
                    <div>{item.bank.name}</div>
                  </div>
                ) :
                <h1>No hay cuentas bancarias asignadas...</h1>
              }
            </div>
          </div>
          {/* <div className="profile-user-content-item">
            <div className="profile-user-content-item-title">
              <FontAwesomeIcon icon={faUserAlt} />
              Perfil
            </div>
            <div className="profile-user-content-item-data">
              <div>
                <div>{}</div>
              </div>
            </div>
          </div> */}
        </div>
      </div> :
        <h1>Cargando...</h1>
      }
    </div>
  )
}