import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faTrash, faUnlock, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { specialityActions } from 'services/Speciality/SpecialitySlice';
import { useForm } from 'react-hook-form';
import { InputForm } from 'components/form/input/InputForm';

export const Speciality = ({ next }) => {
  const [listSpecialities, setListEspecialities] = useState([]);
  const [listSpecialitiesPayload, setListEspecialitiesPayload] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [subCategoryName, setSubCategoryName] = useState('');
  const { register, errors, handleSubmit, reset } = useForm();

  const { currentUserData } = useSelector(state => state.auth)
  const { specialities, subSpecialities } = useSelector(state => state.speciality)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(specialityActions.getSpecialities())
  }, [])

  const handleCategoryChange = e => {
    let index = e.target.selectedIndex;
    setCategoryName(e.target[index].text)
    dispatch(specialityActions.getSubSpecialities(e.target.value))
  }

  const handleSubCategoryChange = e => {
    let index = e.target.selectedIndex;
    setSubCategoryName(e.target[index].text)
  }

  const deleteCategory = (index) => {
    setListEspecialities(listSpecialities.filter((t, i) => i !== index))
    setListEspecialitiesPayload(listSpecialitiesPayload.filter((t, i) => i !== index))
  }

  const onSubmit = data => {
    let categories = { categoryName: categoryName, subCategoryName: subCategoryName }
    let specialities = listSpecialities
    let payload = listSpecialitiesPayload
    let spec = {
      "specializable_type": "user",
      "specializable_id": currentUserData.id,
      "speciality_type_id": data.subcategory ? data.subcategory : data.category,
      "warranty": data.garantia,
      "range": 200
    }
    specialities.push({ ...data, ...categories })
    payload.push(spec)
    setListEspecialities(specialities)
    setListEspecialitiesPayload(payload)
    reset()
  }

  const nextStep = () => {
    let data = {
      specialities: listSpecialitiesPayload
    }
    next(data)
  }

  return (
    <div className="form-container form-speciality">
      <form onSubmit={handleSubmit(onSubmit)} className="form-container-wrapper">
        <div className="form-row">
          <div className="form-column">
            <div className={errors.category ? "form-error-input" : ''}>
              <div className="form-item">
                <FontAwesomeIcon icon={faUnlock} />
                <select name="category" defaultValue="" ref={register({ required: 'Campo obligatorio' })} onChange={(e) => handleCategoryChange(e)}>
                  <option value="" disabled>Categoria</option>
                  {specialities?.map((item, key) =>
                    <option key={key} value={item.id}>{item.name}</option>
                  )}
                </select>
              </div>
              {errors.category && <span className="form-error-msg">{errors.category.message}</span>}
            </div>
            <div className={errors.subcategory ? "form-error-input" : ''}>
              <div className="form-item">
                <FontAwesomeIcon icon={faUnlock} />
                <select name="subcategory" defaultValue="" ref={register({ required: false })} onChange={(e) => handleSubCategoryChange(e)}>
                  <option value="" disabled>Subcategorias</option>
                  {subSpecialities.length > 0 ?
                    subSpecialities?.map((item, key) =>
                      <option key={key} value={item.id}>{item.name}</option>
                    ) :
                    <option value={0}>No hay Subcategorias asociadas</option>}
                </select>
              </div>
              {errors.subcategory && <span className="form-error-msg">{errors.subcategory.message}</span>}
            </div>
            <div className={errors.garantia ? "form-error-input" : ''}>
              <InputForm
                icon={faUserCheck}
                name="garantia"
                type="number"
                placeholder="Garantía (en meses)"
                refHook={register({ required: 'Campo obligatorio' })}
              />
              {errors.garantia && <span className="form-error-msg">{errors.garantia.message}</span>}
            </div>
          </div>
          <div className="form-column">
            <div className={errors.descripcion ? "form-error-input" : ''}>
              <div className="form-item form-item-text-area">
                <textarea name="descripcion"
                  placeholder="Descripción"
                  rows="7" cols="35"
                  ref={register({ required: 'Campo obligatorio' })} />
              </div>
              {errors.descripcion && <span className="form-error-msg">{errors.descripcion.message}</span>}
            </div>
            <div>
              <button type="submit">Agregar {listSpecialities.length > 0 && 'otra'} Especialidad</button>
            </div>
          </div>
        </div>
        {listSpecialities.length > 0 && <div className="list-specialities">
          {listSpecialities?.map((speciality, i) =>
            <div className="speciality-card" key={i}>
              <div className="speciality-card-data">
                <div className="speciality-card-category">
                  <p>{speciality.categoryName}</p>
                  <span>{speciality.subCategoryName}</span>
                </div>
                <div className="speciality-card-description">{speciality.descripcion}</div>
                <div className="speciality-card-warranty"><span>{speciality.garantia}</span> {speciality.garantia > 1 ? 'meses' : 'mes'} de garantía</div>
              </div>
              <FontAwesomeIcon icon={faMinusCircle} onClick={() => deleteCategory(i)} />
            </div>
          )}
        </div>}

        <div className="specialist-container-button">
          <button type="button" onClick={() => nextStep()} disabled={listSpecialities.length <= 0} >Siguiente</button>
        </div>
      </form>
    </div >
  )
}