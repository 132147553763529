import createAction from '../../common/utils/create-action';

const asyncActions = createAction({
  user: [
    'completeness',
    'validateEmail',
    'sendEmailValidate',
    'setMetadata'
  ]
})

export default asyncActions