import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { SignUp } from "scenes/Public/Specialist/Signup/Signup";
import { Login } from "./Specialist/Login/Login";
import { Property } from "./Property/Property";
import { SignUpUsers } from "./Users/SignupUsers/SignupUsers";
import { Downloads } from "./components/Layout/Download/Download";
import { RecoverPassword } from "./components/RecoverPassword/RecoverPassword";
import Feedback from "components/Modals/Feedback/Feedback";
import { newForms } from "./Users/newForms/newForms";
import { FormProperty } from "./Forms/FormProperty/FormProperty";
import { FormReference } from "./Forms/FormReference/FormReference";
import { FormSteps } from "./Landing/FormSteps/FormSteps";
import { ValidateEmail } from "./ValidateEmail/ValidateEmail";

export const Public = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/especialista" component={Login} />
        <Route exact path="/recover-password" component={RecoverPassword} />
        <Route exact path="/registrate/especialista" component={SignUp} />
        <Route exact path="/registrate/:form" component={SignUpUsers} />
        <Route exact path="/registrate" component={SignUpUsers} />
        <Route exact path="/properties" component={Property} />
        <Route exact path="/descargas" component={Downloads} />
        <Route exact path="/newForms" component={newForms} />
        <Route exact path="/referencia" component={FormReference} />
        <Route exact path="/inmueble" component={FormProperty} />
        <Route exact path="/FormSteps" component={FormSteps} />
        <Route exact path="/validate-email" component={ValidateEmail} />
        <Route component={Login} />
      </Switch>
      <Feedback />
    </Router>
  );
};
