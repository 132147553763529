import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Property } from 'scenes/Public/Property/Property';
import { Invitation } from 'scenes/Private/Invitation/Invitation';
import { Specialist } from './Specialist/Specialist';
import { Header } from 'components/layout/Header/Header';
import { Profile } from './Specialist/components/Steps/Profile/Profile';
import { Speciality } from './Specialist/components/Steps/Speciality/Speciality';
import { Accounts } from './Specialist/components/Steps/Accounts/Accounts';
import { Login } from 'scenes/Public/Specialist/Login/Login';
import { useDispatch, useSelector } from 'react-redux';
import { StorageService } from 'common/storage/storage-service';
import { Downloads } from 'scenes/Public/components/Layout/Download/Download';
import { SignUpUsers } from 'scenes/Public/Users/SignupUsers/SignupUsers';
import { SignUp } from 'scenes/Public/Specialist/Signup/Signup';
import { ProfileUser } from './ProfileUser/ProfileUser';
import { userActions } from 'services/User/UserSlice';
import Feedback from 'components/Modals/Feedback/Feedback';
import { Loader } from 'components/layout/Loader/Loader';
import { ValidateEmail } from 'scenes/Public/ValidateEmail/ValidateEmail';

export const Private = () => {
  const onBoardFinished = StorageService.getItem('spec') || false
  const [goHome, toggleGoHome] = useState(onBoardFinished);
  const { userInfo } = useSelector(state => state.user)
  const { status } = useSelector(state => state.auth)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userActions.completeness())
    StorageService.getItem('spec')
      .then((value) => value == 'true' ? toggleGoHome(true) : toggleGoHome(userInfo?.phone ? true : false))
    //  toggleGoHome(false)
  }, [])

  return (
    <Router>
      <Header />
      <Switch className="h-100">
        <Route exact path="/" component={goHome || userInfo?.contact ? Invitation : Specialist} />
        <Route exact path="/invitations" component={Invitation} />
        <Route exact path="/perfil" component={ProfileUser} />
        <Route exact path="/onboarding" component={Specialist} />
        <Route exact path="/property" component={Property} />
        <Route exact path="/descargas" component={Downloads} />
        <Route exact path="/registrate/especialista" component={SignUp} />
        <Route exact path="/registrate/:form" component={SignUpUsers} />
        <Route exact path="/registrate" component={SignUpUsers} />
        <Route exact path="/validate-email" component={ValidateEmail} />
      </Switch>
      <Feedback />
      <Loader isLoading={status === 'loading'} />
    </Router>
  )
}