import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './LocalizationActions';

const initialState = {
  status: null,
  errormsg: null,
  localization: [],
}

const localizationSlice = createSlice({
  name: "localization",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
  }
});

const localizationActions = { ...localizationSlice.actions, ...asyncActions }
const localizationReducer = localizationSlice.reducer

export { localizationActions, localizationReducer };