import createAction from '../../common/utils/create-action';

const asyncActions = createAction({
  ticket: [
    'createTicket',
    'getTickets',
    'createSchedule',
    'getTicketsAssociates',
    'getTicketsDescriptions',
    'activateTicket',
    'addTicketCost',
    'getTicketCart',
    'getTicket',
    "acceptCost",
    'getTicketProcess',
    'updateTicket'
  ]
})
export default asyncActions