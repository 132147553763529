import { put, all, takeLatest } from 'redux-saga/effects';
/* import { Alert } from 'react-native'; */
import { shoppingActions } from './ShoppingSlice';
import ApiService from '../../common/api/Api';

function* getShopping() {
  yield put(shoppingActions.onChangeStatus('loading'))
  try {
    const location = yield ApiService.get('/shopping')
    yield put(shoppingActions.setCarServices(location))
    yield put(shoppingActions.onChangeStatus('success'))
  } catch (err) {
    yield put(shoppingActions.onError(err.toString()))
  }
}

function* ActionWatcher() {
  yield takeLatest(shoppingActions.getShopping, getShopping)
}

export default function* ShoppingSaga() {
  yield all([
    ActionWatcher(),
  ]);
}
