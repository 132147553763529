import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  faUser,
  faUnlock,
  faEnvelope,
  faUserAlt,
  faPhone,
  faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useQuery } from "common/utils/use-query";
import { InputCenter } from "components/form/input-center/InputCenter";
import { formsActions } from "services/Forms/FormsSlice";

import "../Form/Form.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Form = () => {
  let history = useHistory();
  const dispatch = useDispatch()
  const override = `
  display: block;
  margin: 0 auto;
  border-color: white;
`;

  const { register, handleSubmit, errors } = useForm();
  const { loading } = useSelector(state => state.forms)
  const [isErrorInfo, setErrorInfo] = useState(false)

  let query = useQuery();

  const onSubmit = (data) => {

    const contact = {
      "contact": {
        "first_name": data?.userName,
        "last_name": data?.lastName,
        "email": data?.email,
        "mobile_number": data?.phone,
        //"tags": query?.get("type")?.split("-")?.map((item) => item?.toUpperCase())
        "tags": [data?.info]
      }
    }

    dispatch(formsActions.createContact({
      data: contact,
      callback: () => succes(contact)
    }))
  };

  const succes = (data) => {

    if (query?.get("type") === "inq" || query?.get("type") === "INQ") {
      history.push("/inmueble", { email: data?.contact?.email });
    } else if (
      query?.get("type") === "inq-ref" ||
      query?.get("type") === "INQ-REF"
    ) {
      history.push("/descargas", { email: data?.contact?.email });
    } else {
      history.push("/descargas");
    }
  }

  return (
    <div className="landing-form">
      <h1>¡Bienvenid@ a la mejor experiencia de arrendamiento!</h1>
      <p>
        Déjanos tus datos
        {query?.get("type") === "owner" ? " propietario" : " inquilino"}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={errors.address ? "form-error-input" : ""}>
          <InputCenter
            icon={faUser}
            name="userName"
            id="userName"
            refHook={register({ required: "Campo obligatorio" })}
            placeholder="Nombres"
            type="text"
          />
          {errors.userName && (
            <span className="form-error-msg">{errors.userName.message}</span>
          )}
        </div>

        <div className={errors.lastName ? "form-error-input" : ""}>
          <InputCenter
            icon={faUserAlt}
            name="lastName"
            id="lastName"
            refHook={register({ required: "Campo obligatorio" })}
            placeholder="Apellidos"
            type="text"
          />
          {errors.lastName && (
            <span className="form-error-msg">{errors.lastName.message}</span>
          )}
        </div>

        <div className={errors.email ? "form-error-input" : ""}>
          <InputCenter
            icon={faEnvelope}
            name="email"
            id="email"
            refHook={register({ required: "Campo obligatorio" })}
            placeholder="Correo"
            type="email"
          />
          {errors.email && (
            <span className="form-error-msg">{errors.email.message}</span>
          )}
        </div>

        <div className={errors.phone ? "form-error-input" : ""}>
          <InputCenter
            icon={faPhone}
            name="phone"
            id="phone"
            refHook={register({ required: "Campo obligatorio" })}
            placeholder="Celular"
            type="phone"
          />
          {errors.phone && (
            <span className="form-error-msg">{errors.phone.message}</span>
          )}
        </div>

        <div className={errors.info ? "form-error-input" : "", "form-item"} style={{ width: "100%", marginLeft: 0 }}>
          <FontAwesomeIcon icon={faHouseUser} />
          <select
            name="info"
            id="info"
            ref={register({ required: "Campo obligatorio" })}
            placeholder="Seleccionar"
            defaultValue=""
          >
            <option value="" disabled>¿Estás interesado(a) en ser:?</option>
            <option value={"INQ"}>Inquilino (asesoría y búsqueda de propiedades en arriendo)</option>
            <option value={"PROP"}>Propietario (asesoría y gestión de tu propiedad)</option>
            <option value={"INQ-REF"}>Referir a un conocido</option>
          </select>

          {errors.info && (
            <span className="form-error-msg">{errors.info.message}</span>
          )}
        </div>

        <div className="hidden">
          <InputCenter
            icon={faUnlock}
            name="tags"
            id="tags"
            refHook={register}
            placeholder="tags"
            type="text"
            defaultValue={query?.get("type")}
          />
        </div>

        <div className="landing-form__check_terms">
          <input
            type="checkbox"
            id="cbox1"
            name="cbox1"
            value={true}
            ref={register({
              required: "Los términos y condiciones son obligatorios",
            })}
          />
          <label>
            He leído y acepto los{" "}
            <a href="https://www.yampi.co/terminos-y-condiciones/">
              Términos y condiciones
            </a>{" "}
            y las{" "}
            <a href="https://www.yampi.co/politica-de-tratamientos-de-datos/">
              Políticas de privacidad
            </a>
            .
          </label>
          {errors.cbox1 && (
            <span style={{ marginTop: 35 }} className="form-error-msg">
              {errors.cbox1.message}
            </span>
          )}
        </div>

        <button type="submit">
          {loading?.createContact ?
            <ClipLoader
              color={"#ffffff"}
              loading={true}
              css={override}
              size={30}
            />
            :
            "Registrate"
          }
        </button>
      </form>
    </div>
  );
};
