import { put, all, takeLatest } from 'redux-saga/effects';
/* import { Alert } from 'react-native'; */
import decode from 'jwt-decode';
import { locationActions } from './LocationSlice';
import ApiService from '../../common/api/Api';
import { StorageService } from '../../common/storage/storage-service';

function* getLocation({ payload }) {
  yield put(locationActions.onChangeStatus('loading'))
  try {
    const location = yield ApiService.get(`/location/2/${payload.q}`)
    yield put(locationActions.setLocations(location))
    yield put(locationActions.onChangeStatus('success'))
  } catch (err) {
    yield put(locationActions.onError(err.toString()))
  }
}

function* getCountries({ }) {
  yield put(locationActions.onChangeStatus('loading'))
  try {
    const countries = yield ApiService.get('/country')
    yield put(locationActions.setCountries(countries))
    yield put(locationActions.onChangeStatus('success'))
  } catch (err) {
    yield put(locationActions.onError(err.toString()))
  }
}

function* ActionWatcher() {
  yield takeLatest(locationActions.getLocation, getLocation)
  yield takeLatest(locationActions.getCountries, getCountries)
}

export default function* LocationSaga() {
  yield all([
    ActionWatcher(),
  ]);
}
