import React from "react";
import "./FormProperty.scss";

import { Footer } from "./Footer/Footer";
import { Benefits } from "./Benefits/Benefits";
import { Form } from "./Form/Form";

export const FormProperty = ({ location }) => {
  return (
    <main className="main">
      <div className="main-content">
        <Benefits />
        <div className="main-row">
          <div className="main-column">
            <Form location={location} />
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};
