import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from "react-hook-form";

import { authActions } from 'services/Auth/AuthSlice';
import { Header } from 'components/layout/Header/Header'
import { FooterCopyright } from 'scenes/Public/components/FooterCopyright/FooterCopyright'
import { LoginForm } from '../../components/Login/LoginForm/LoginForm';
import { useHistory } from "react-router-dom";
import { Loader } from 'components/layout/Loader/Loader';

export const Login = () => {
  const { status } = useSelector(state => state.auth)
  let history = useHistory()

  return (
    <div className="login">
      <Header />
      <div className="login-content">
        <div className="login-signup">
          <div className="login-sign">
            <h1 className="login-sign-user">¿NO TIENES UNA CUENTA?</h1>
            <p className="login-sign-yampi">Si haces mantenimientos locativos de apartamentos residenciales y quieres encontrar trabajo por medio de Yampi, regístrate y ¡empieza a recibir ofertas!</p>
            <button className="login-sign-button" onClick={() => history.push("/registrate/especialista")}>CREAR CUENTA</button>
          </div>
        </div>
        <div className="login-row">
          <div className="login-column">
            <LoginForm />
          </div>
        </div>
      </div>
      <Loader isLoading={status === 'loading'} />
      <FooterCopyright />
    </div>
  )
}

