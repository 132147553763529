import React from 'react';

export const Informative = ({ invitations, totalAmount }) => {
  return (
    <div className="informative-detail">
      <div className="informative-sub-title">
        <span>Resumen del mes</span>
      </div>
      <div className="informative-content-detail">
        <div className="informative-slopes">
          <div className="informative-quantity">
            <h6>{invitations?.pending?.length || '0'}</h6>
          </div>
          <div>
            <h4>Solicitudes pendientes</h4>
          </div>
        </div>
        <div className="informative-active">
          <div className="informative-quantity">
            <h6>{invitations?.actives?.length || '0'}</h6>
          </div>
          <div>
            <h4>Solicitudes activas</h4>
          </div>
        </div>
        <div className="informative-closed">
          <div className="informative-quantity">
            <h6>{invitations?.closed?.length || '0'}</h6>
          </div>
          <div>
            <h4>Solicitudes cerradas</h4>
          </div>
        </div>
        <div className="informative-services">
          <div className="informative-quantity">
            <h6>$ {totalAmount || '0'}</h6>
          </div>
          <div>
            <h4>Valor de servicio</h4>
          </div>
        </div>
      </div>
    </div>
  )
}