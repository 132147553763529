import { put, all, takeLatest } from 'redux-saga/effects';
import ApiService from '../../common/api/Api';
import { localizationActions } from './LocalizationSlice';

function* getLocalization({ payload }) {
  try {
    yield put(localizationActions.onChangeStatus('loading'))
    const response = yield ApiService.post(`/feature/${payload.propertyId}/property`, payload);
    yield put(localizationActions.onChangeStatus('success'))
  } catch (err) {
    yield put(localizationActions.onError('No se pudo obtener la ubicación'))
  }
}

function* saveLocalization({ payload }) {
  try {
    yield put(localizationActions.onChangeStatus('loading'))
    const response = yield ApiService.post(`localization/${payload.userId}/user`, payload.body);
    yield put(localizationActions.onChangeStatus('success'))
  } catch (err) {
    yield put(localizationActions.onError('No se pudo registrar la ubicación'))
  }
}

function* updateLocalization({ payload }) {
  try {
    yield put(localizationActions.onChangeStatus('loading'))
    const response = yield ApiService.put(`localization/${payload.userId}/user`, payload.body);
    yield put(localizationActions.onChangeStatus('success'))
  } catch (err) {
    yield put(localizationActions.onError('No se pudo registrar la ubicación'))
  }
}

function* ActionWatcher() {
  yield takeLatest(localizationActions.getLocalization, getLocalization)
  yield takeLatest(localizationActions.saveLocalization, saveLocalization)
  yield takeLatest(localizationActions.updateLocalization, updateLocalization)
}

export default function* LocalizationSaga() {
  yield all([
    ActionWatcher(),
  ]);
}

