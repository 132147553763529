import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './BankActions';

const initialState = {
  status: null,
  errormsg: null,
  loading: true,
  banks: [],
  bankAccountTypes: [],
  bankAccount: []
}

const bankSlice = createSlice({
  name: "bank",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setBanks(state, { payload }) {
      state.banks = payload;
    },
    setBankAccountTypes(state, { payload }) {
      state.bankAccountTypes = payload;
    },
    setBankAccount(state, { payload }) {
      state.bankAccount = payload.data
    }
  }
});

const bankActions = { ...bankSlice.actions, ...asyncActions }
const bankReducer = bankSlice.reducer

export { bankActions, bankReducer };