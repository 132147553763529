import createAction from '../../common/utils/create-action';

const asyncActions = createAction({
  properties: [
    'getAll',
    'getTicketSchedule',
    'acceptCitation',
    'createSchedule',
    'getScheduleByEntity'
  ]
})
export default asyncActions