import { put, all, takeLatest, select } from 'redux-saga/effects';
/* import { Alert } from 'react-native'; */
import ApiService from '../../common/api/Api';
import { bankActions } from './BankSlice';

function* getBanks({ payload }) {
  yield put(bankActions.onChangeStatus('loading'))
  try {
    const bank = yield ApiService.get('/bank', { country_id: payload })
    yield put(bankActions.setBanks(bank))
    yield put(bankActions.onChangeStatus('success'))
  } catch (err) {
    yield put(bankActions.onError(err.toString()))
  }
}

function* getBanksAccountType({ payload }) {
  yield put(bankActions.onChangeStatus('loading'))
  try {
    const bankAccountType = yield ApiService.get('/bank-account-type', { country_id: payload })
    yield put(bankActions.setBankAccountTypes(bankAccountType))
    yield put(bankActions.onChangeStatus('success'))
  } catch (err) {
    yield put(bankActions.onError(err.toString()))
  }
}

function* addBankAccount({ payload }) {
  try {
    const user = yield select((state) => state.auth.currentUserData);
    yield ApiService.post(`bank-account/${user.id}/user`, payload)
    yield put(bankActions.getBankAccount())
  } catch (err) {
    yield put(bankActions.onError(err.toString()))
  }
}

function* getBankAccount({ payload }) {
  yield put(bankActions.onChangeStatus('loading'))

  try {
    const response = yield ApiService.get(`/bank-account`)
    yield put(bankActions.setBankAccount(response))
    yield put(bankActions.onChangeStatus('success'))
  } catch (err) {
  }
}

function* ActionWatcher() {
  yield takeLatest(bankActions.getBanks, getBanks)
  yield takeLatest(bankActions.getBanksAccountType, getBanksAccountType)
  yield takeLatest(bankActions.addBankAccount, addBankAccount)
  yield takeLatest(bankActions.getBankAccount, getBankAccount)
}

export default function* BankSaga() {
  yield all([
    ActionWatcher(),
  ]);
}
