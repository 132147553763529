import React from "react";

import { Main } from "../../Landing/Main/Main";

export const newForms = () => {
  return (
    <div>
      <Main />
    </div>
  );
};
