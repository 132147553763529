import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './SpecialityActions';

const initialState = {
  status: null,
  errormsg: null,
  specialityUser: null,
  specialities: [],
  subSpecialities: [],
  userSpecialities: null,
  loading: {
    registerSpecialities: false,
    createSpecialitie: false,
    getUserSpeciality: false,
    updateSpeciality: false,
    deleteSpeciality: false
  },
  error: {
    registerSpecialities: false,
    createSpecialitie: false,
    getUserSpeciality: false,
    updateSpeciality: false,
    deleteSpeciality: false
  },
}

const specialitySlice = createSlice({
  name: "speciality",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setError(state, { payload: { key, newState } }) {
      state.error = { ...state.error, [key]: newState };
    },
    setSpecialities(state, { payload }) {
      state.specialities = payload;
    },
    setSubSpecialities(state, { payload }) {
      state.subSpecialities = payload;
    },
    setUserSpecialities(state, { payload }) {
      state.userSpecialities = payload;
    },
    setSpecialityUser(state, { payload }) {
      state.specialityUser = payload;
    },
    setLoading(state, { payload: { key, newState } }) {
      state.loading = { ...state.loading, [key]: newState }
    }
  }
});

const specialityActions = { ...specialitySlice.actions, ...asyncActions }
const specialityReducer = specialitySlice.reducer

export { specialityActions, specialityReducer };