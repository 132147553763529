import { put, all, takeLatest, call } from 'redux-saga/effects';
import { ticketActions } from './TicketSlice';
import { appActions } from '../App/AppSlice';
import ApiService from '../../common/api/Api';

function* getTicketsAssociates() {
  try {
    yield put(ticketActions.onChangeStatus('loading'))
    const response = yield ApiService.get(`/ticket-associate`)
    yield put(ticketActions.setTicketsAssociates(response.data))
    yield put(ticketActions.onChangeStatus('success'))
  } catch (err) {
    yield put(ticketActions.onError('No se pudieron obtener los tickets asociados'))
  }
}

function* getTicket({ payload }) {
  try {
    yield put(ticketActions.onChangeStatus('loading'))
    const response = yield ApiService.get(`/ticket/${payload}`)
    yield put(ticketActions.setTicket(response))
    yield put(ticketActions.onChangeStatus('success'))
  } catch (err) {
    yield put(ticketActions.onError('No se pudo obtener el ticket'))
  }
}

function* updateTicket({ payload }) {
  try {
    yield put(ticketActions.onChangeStatus('loading'))
    yield ApiService.put(`/ticket/${payload.ticket_id}`, payload)
    yield put(ticketActions.onChangeStatus('success'))
  } catch (err) {
    yield put(ticketActions.onError('No se pudo actualizar el ticket'))
  }
}

function* getTicketsDescriptions({ payload }) {
  yield put(ticketActions.onChangeStatus('loading'))
  let descriptions = []
  try {
    yield* payload.map(async function (item) {
      try {
        const description = await ApiService.get('/content/' + item.id + '/ticket')
        descriptions.push({
          id: item.id,
          description: description.data[0]
        })
      } catch (e) {
        return null;
      }
    })
    yield put(ticketActions.setDescriptions(descriptions))
    yield put(ticketActions.onChangeStatus('success'))
  } catch (err) {
    yield put(ticketActions.onError('No se pudieron obtener los tickets asociados'))
  }
}

function* activateTicket({ payload }) {
  try {
    yield put(ticketActions.onChangeStatus('loading'))
    yield ApiService.patch(`ticket/approve/` + payload)
    yield put(ticketActions.getTicketsAssociates())
    yield put(ticketActions.onChangeStatus('success'))
  } catch (err) {
    yield put(ticketActions.onError('No se pudo activar el ticket'))
  }
}

function* addTicketCost({ payload }) {

  yield put(ticketActions.setLoadingTicket({ key: "addTicketCost", newState: true }))

  try {
    const response = yield ApiService.post(`cost/${payload?.data[0].ticket_id}/ticket`, payload?.data)
    payload.callback && payload.callback()

  } catch (err) {
    yield put(ticketActions.onError('No se pudo asignar el costo al ticket'))
  }

  yield put(ticketActions.setLoadingTicket({ key: "addTicketCost", newState: false }))
}

function* acceptCost({ payload }) {

  yield put(ticketActions.setLoadingTicket({ key: "acceptCost", newState: true }))

  try {
    const response = yield ApiService.get(`ticket-pay/${payload?.ticket_id}`)
    console.log("response:", response);
    payload.callback && payload.callback()
  } catch (err) {
    yield put(ticketActions.onError('No se pudo asignar el costo al ticket'))
  }

  yield put(ticketActions.setLoadingTicket({ key: "acceptCost", newState: false }))
}

function* createTicket({ payload }) {
  yield put(ticketActions.setLoading(true))
  try {
    const response = yield ApiService.post('ticket', {
      "priority_id": payload.priority_id,
      "property_id": payload.property_id,
      renter_id: payload.renter,
      contract_id: payload.contract_id,
      contractable_id: payload.contractable_id
    })

    if (response.id) {

      payload.callback && payload.callback(response.id)

      const newTicket = yield ApiService.post(`content/${response.id}/ticket`, {
        "title": payload.title,
        "body": payload.description,
        "content_type": { "id": 1 },
        "language": { "id": 1 }
      })

      const speciality = yield ApiService.post(`speciality`,
        {
          "specializable_type": "ticket",
          "specializable_id": response.id,
          "speciality_type_id": payload.speciality,
          "warranty": 0,
          "range": 0
        })

      if (speciality) {
        const publish = yield ApiService.post(`ticket/near/${response.id}`, {})
        if (publish) {
          yield put(ticketActions.setLoading(false))
        }
      }
    }
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Completado',
      feedbackDescription: 'Tu ticket de soporte ha sido enviado',
      feedbackIcon: ''
    }))
  } catch (err) {
    console.log("err:", err);
    yield put(ticketActions.setLoading(false))
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: 'Ocurrió un error al crear el ticket, vuelve a intentarlo mas tarde',
      feedbackIcon: ''
    }))
  }

}

function* getTickets({ payload }) {

  try {
    const response = yield ApiService.get(`/ticket-prop/${payload}`)

    yield put(ticketActions.setTickets(response))
  } catch (err) {
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: 'Ocurrió un error al traer los mantenimientos',
      feedbackIcon: ''
    }))
  }
}

function* createSchedule({ payload }) {
  try {
    const response = yield ApiService.post('schedule', payload)
    /* let TicketFindByProperty = response.data.filter(ticket => {
      return ticket.property_id == payload;
    }) */
    /* yield put(ticketActions.setTickets(TicketFindByProperty)) */
  } catch (err) {
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: 'Ocurrió un error al agendar las visitas',
      feedbackIcon: ''
    }))
  }
}

function* getTicketCart({ payload }) {
  try {
    yield put(ticketActions.onChangeStatus('loading'))
    const response = yield ApiService.get(`/ticket-carts/${payload}`) //ticket_id
    yield put(ticketActions.setTicketCart(response))
    yield put(ticketActions.onChangeStatus('success'))
  } catch (err) {
    yield put(ticketActions.onError('No se pudo obtener el carrito del ticket'))
    let erroObj = JSON.parse(err.message);
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: 'Ocurrió un error al obetener los pagos del ticket',
      feedbackIcon: ''
    }))
  }
}

function* getTicketProcess({ payload }) {

  yield put(ticketActions.setLoadingTicket({ key: "getTicketProcess", newState: true }))

  try {
    const response = yield ApiService.get(`ticket-cost/${payload?.propertyId}`)
    yield put(ticketActions.setTicketProcess(response))
    payload.callback && payload.callback()

  } catch (err) {
    yield put(ticketActions.onError('No se pudo asignar el costo al ticket'))
  }

  yield put(ticketActions.setLoadingTicket({ key: "getTicketProcess", newState: false }))
}

function* ActionWatcher() {
  yield takeLatest(ticketActions.createTicket, createTicket)
  yield takeLatest(ticketActions.getTickets, getTickets)
  yield takeLatest(ticketActions.createSchedule, createSchedule)
  yield takeLatest(ticketActions.getTicketsAssociates, getTicketsAssociates)
  yield takeLatest(ticketActions.getTicketsDescriptions, getTicketsDescriptions)
  yield takeLatest(ticketActions.activateTicket, activateTicket)
  yield takeLatest(ticketActions.addTicketCost, addTicketCost)
  yield takeLatest(ticketActions.getTicket, getTicket)
  yield takeLatest(ticketActions.getTicketCart, getTicketCart)
  yield takeLatest(ticketActions.acceptCost, acceptCost)
  yield takeLatest(ticketActions.getTicketProcess, getTicketProcess)
  yield takeLatest(ticketActions.updateTicket, updateTicket)
}

export default function* TicketSaga() {
  yield all([
    ActionWatcher(),
  ]);
}
