import React, { useState, useEffect } from 'react';

export const Loader = ({ isLoading }) => {


  return (
    isLoading && <div className="loader">
      <div className="loader-bkg"></div>
      <div className="loader-content">
        <img src={require('assets/logo.svg')} alt='logo' className="header__logo_yampi" />
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  )
}