import React, { useEffect, useState } from 'react';
import { getMonthName, getDayName, expiredDate } from 'common/utils/dates';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { invitationActions } from 'services/Invitation/InvitationSlice';
import { scheduleActions } from 'services/Schedule/ScheduleSlice';
import { ticketActions, ticketReducer } from 'services/Ticket/TicketSlice';
import { useForm } from 'react-hook-form';
import { InputForm } from 'components/form/input/InputForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCalendarAlt, faCommentDollar, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { mediaActions } from 'services/Media/MediaSlice';
import { StorageService } from 'common/storage/storage-service';
import { FormTicketCost } from '../FormTicketCost/FormTicketCost';
import ImageUploader from "react-images-upload";
import { apiUrl } from 'common/config/Environments';

export const InvitationDetail = ({ invitation, backAction }) => {
  const dispatch = useDispatch()
  const [scheduleList, setScheduleList] = useState([])
  const [negotiate, isNegotiating] = useState(false)
  const [visitImage, setVisitImage] = useState('')
  const [fixImage, setFixImage] = useState('')
  const { register, handleSubmit, errors } = useForm();
  const media = useSelector(state => state.media)
  const { scheduleTicket, loading: loadingSchedule } = useSelector(
    (state) => state.schedule
  )
  const { ticket, ticketCart, loadingTicket, ticketProcess } = useSelector(state => state.ticket)
  const [isIndexDateSelect, setIndexDateSelect] = useState(undefined);
  const [modalMessage, setModalMessage] = useState(false);
  const [isPdfs, setPdfs] = useState()
  const [isImages, setImages] = useState()
  const [token, setToken] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    StorageService.getItem('token').then(token => { setToken(token) })
  }, [token])

  useEffect(() => {
    const schedulePayload = {
      ticket_id: invitation.invitable_id,
      type: 1
    }
    dispatch(scheduleActions.getTicketSchedule(schedulePayload))
    dispatch(ticketActions.getTicket(invitation.invitable_id))
    dispatch(mediaActions.getMedia({ entityId: invitation.invitable_id, entityName: 'ticket' }))
  }, [])

  useEffect(() => {
    if (ticket.id) {
      let visitPic = ticket?.attachments?.filter(t => t.metadata.includes('"ticket_step\": \"visit\"'))[0]
      let fixPic = ticket?.attachments?.filter(t => t.metadata.includes('"ticket_step\": \"fix\"'))[0]
      dispatch(ticketActions.getTicketCart(ticket.id))
      dispatch(mediaActions.getMedia({ entityId: ticket.id, entityName: 'ticket' }))
      setVisitImage(visitPic?.filepath + visitPic?.filename)
      setFixImage(fixPic?.filepath + fixPic?.filename)
    }
  }, [ticket])

  useEffect(() => {
    if (scheduleTicket.length > 0) {
      let list = []
      if (scheduleTicket.findIndex(t => t.schedule_type_id === 3) >= 0) {
        list = scheduleTicket.filter(t => t.schedule_type_id === 3)
      } else {
        list = scheduleTicket.filter(t => t.schedule_type_id === 1)
      }
      setScheduleList(list)
    }
  }, [scheduleTicket])

  useEffect(() => {
    if (media) {
      setPdfs(media?.media?.filter((item) => item?.filename?.split(".")?.pop() == "pdf"))
      setImages(media?.media?.filter((item) => item?.filename?.split(".")?.pop() == "png" || item?.filename?.split(".")?.pop() == "jpg"))
    }
  }, [media])

  const stateInvitation = (id) => {
    const states = ["Abierta", "En progreso", "Detenida", "Cancelada", "Reabierta", "Cerrada",
      "Rechazada", "Aceptada", "Reagendada", "Asignada", "Por pagar", "Pagada", "Entregada", "Devuelta",
      "En entrega", "En retorno"]
    return states[id - 1]
  }

  const activateInvitation = () => {
    //aprobar ticket    
    dispatch(ticketActions.activateTicket(invitation?.invitable_id))
    dispatch(invitationActions.activateInvitation(invitation.id))
    backAction()
  }

  const acceptDate = (data) => {
    const payload = {
      "id": data.id, //id_eschedule
      "status_id": 8, //siempre 8, recibe 1 sin aceptar 
      "date": data.date //fecha aceptada
    }
    const payloadAccept = {
      "ticket_id": ticket.ticket_id,
      "status_id": ticket.status_id,
      "priority_id": ticket.priority_id,
      "status_visit": ticket.status_visit + 1,
    }
    dispatch(scheduleActions.acceptCitation(payload))
    dispatch(ticketActions.updateTicket(payloadAccept))
    backAction();
  }

  const checkPaymentStatus = () => {
    let response = ""
    let firstPay = ticketCart.filter(t => t.metadata.includes('"order":0'))[0]
    let secondPay = ticketCart.filter(t => t.metadata.includes('"order":1'))[0]


    if (ticketCart.length <= 0) {
      response = `En espera de que el costo del mantenimiento ($${costNegotiate()}) sea aceptado o negociado`
    } else {
      if (firstPay?.status_id === 12) {
        response = 'El primer pago del mantenimiento se ha efectuado'
        if (secondPay?.status_id === 12) {
          response = 'El pago del mantenimiento se ha efectuado'
        } else {
          response = `En espera del pago ($${secondPay?.total_amount}) por mantenimiento`
        }
      } else {
        response = `En espera del primer pago ($${firstPay?.total_amount}) por mantenimiento`
      }
    }
    return response
  }

  const costNegotiate = () => {
    if (ticket?.costs?.length > '')
      return ticket?.costs[0]?.value
  }

  const negotiateCostForm = (data) => {
    const payload = [
      {
        ticket_id: ticket?.id,
        cost_type: { id: ticket?.costs[0]?.cost_type_id }, //valor al inquilino(7) o al dueño(11)
        currency: { id: 2 },
        value: data.ticketCost,
      },
    ];
    dispatch(ticketActions.addTicketCost({ data: payload, callback: backAction }));
    isNegotiating(false)
  }

  const onDrop = picture => {
    setFile(picture[0]);
  };

  const succesCreateFile = () => {
    let firstPay = ticketCart.filter(t => t.metadata.includes('"order":0'))[0]
    dispatch(mediaActions.uploadMedia({
      "media": file,
      "parentId": ticket.id,
      "group": "ticket_content",
      "name": file.name,
      "ticketVerify": firstPay?.status_id === 12 ? 2 : 1
    }))
    backAction()
  }

  return (
    <div className="invitation-detail">
      <div className="invitation-detail-header">
        <div>
          <h3>Solicitud N° {invitation.id}</h3>
          <p>{`Propiedad ${ticket?.property?.title}`}</p>
          <p>{`${ticket?.property?.localization?.address} - ${ticket?.property?.localization?.location?.fullname}`}</p>
          <p>Creada por {invitation?.from_user?.profile?.name} {invitation?.from_user?.profile?.lastname} el {invitation?.created_at?.substring(8, 10)} {getMonthName(invitation?.created_at)} </p>
        </div>
        <div className="invitation-detail-state">
          <h4>Estado actual</h4>
          <p className={invitation.status.name}>{stateInvitation(invitation.status_id)}</p>
        </div>
      </div>
      { scheduleTicket.length == 0 &&
        <div className="invitation-detail-body">
          {invitation.description && <h4>Descripción</h4>}
          {invitation.description ?
            <div className="invitation-detail-description">
              <h4>{invitation?.description?.title}</h4>
              <p>{invitation?.description?.body}</p>
            </div> :
            <div className="invitation-detail-description">
              <h4>Sin descripción</h4>
              <p>{invitation?.from_user?.profile?.name} no ha proporcionado una descripción</p>
            </div>}
        </div>}
      {scheduleTicket.length > 0 ?
        !(scheduleList.findIndex(t => t.status_id === 1) >= 0) ?
          <div>
            {!expiredDate(scheduleList.filter(t => t.status_id === 8)[0]?.date) ?
              <div className="invitation-detail-advice">
                <FontAwesomeIcon icon={faCalendarCheck} />
              Se agendó cita para el
              {` ${scheduleList.filter(t => t.status_id === 8)[0]?.date.substring(8, 10)} de ${getMonthName(scheduleList.filter(t => t.status_id === 8)[0]?.date)}
            a las ${scheduleList.filter(t => t.status_id === 8)[0]?.date.substring(10, 16)}`}
              </div> :
              ticket?.costs?.length <= 0 ?
                <div className="form-price">
                  <p className="invitation-detail-title">
                    <FontAwesomeIcon icon={faCommentDollar} />
                  Dinos cuánto cuestan los arreglos al domicilio</p>
                  <FormTicketCost
                    isInvitation={invitation}
                    succesCreateFile={succesCreateFile}
                    setBufferFile={setFile}
                    file={file}
                  />
                </div>
                :
                <div className="form-price">
                  <p className="invitation-detail-advice">
                    <FontAwesomeIcon icon={faCommentDollar} />
                    {checkPaymentStatus()}</p>
                  {
                    (ticketCart?.filter(t => t.metadata.includes('"order":0'))[0]?.status_id == 12 && ticketCart?.filter(t => t.metadata.includes('"order":1'))[0]?.status_id != 12) &&
                    <div className="upload-content">
                      {ticket?.attachments?.filter(t => t.metadata.includes('"ticket_step\": \"fix\"')).length == 0 &&
                        <div>
                          <h3>Adjunte una evidencia del estado final del servicio:</h3>
                          <ImageUploader
                            withIcon={true}
                            onChange={onDrop}
                            singleImage={true}
                            label="Archivo máximo de 5mb, solo jpg, png, gif"
                            fileSizeError="El peso de la imagen es mayor a 5mb"
                            fileTypeError="EL tipo de archivo no es válido"
                            buttonText="Adjuntar imagen"
                            withPreview={true}
                            imgExtension={[".jpg", ".gif", ".png"]}
                            maxFileSize={5242880} />
                          <button className="btn-primary" onClick={() => succesCreateFile()}>Subir imagen</button>
                        </div>}
                    </div>
                  }
                  {ticketCart.length <= 0 &&
                    <div className="negotiate">
                      {negotiate ?
                        <form onSubmit={handleSubmit(negotiateCostForm)}>
                          <div className={errors.ticketCost ? "form-error-input" : ''}>
                            <InputForm
                              icon={faMoneyCheckAlt}
                              name="ticketCost"
                              type="number"
                              placeholder="Nuevo costo del servicio"
                              refHook={register({ required: 'Campo obligatorio' })}
                            />
                            {errors.ticketCost && <span className="form-error-msg">{errors.ticketCost.message}</span>}
                          </div>
                          <input type="submit" />
                        </form>
                        :
                        <button className="btn-primary" onClick={() => isNegotiating(true)}>Negociar</button>
                      }
                    </div>
                  }
                </div>
            }
          </div>
          :
          <div className={`date-list`}>
            <div className="date-list-header">
              <p>{invitation?.from_user?.profile?.name} está disponible para tu visita en estos horarios</p>
              <p>Selecciona la que sea más oportuna para tí</p>
            </div>
            <div className="date-badge-list">
              {scheduleList.filter(t => t.status_id === 1).map((item, i) =>
                <div className={`date-badge ${item.status_id === 8 ? 'date-badge-active' : ''}`} key={i}>
                  <p>{getDayName(item.date)} {`${item.date.substring(8, 10)} de ${getMonthName(item.date)} a las ${item.date.substring(10, 16)}`}</p>
                  <button onClick={() => acceptDate(item)}>Aceptar</button>
                </div>
              )}
            </div>
          </div>
        :
        invitation.status_id !== 1 &&
        <div className="invitation-detail-advice">
          <FontAwesomeIcon icon={faBell} />
          Te notificaremos cuando {invitation?.from_user?.profile?.name} asigne horarios de visita </div>
      }
      <div className="row">
        {visitImage.length > 0 && <div className="upload-content-evidence">
          <h3>Evidencia del estado en la primera visita</h3>
          <br />
          <img
            crossOrigin="anonymous"
            src={apiUrl + visitImage}
            alt={`Evidencia antes de`}
          />
        </div>}
        {fixImage.length > 0 && <div className="upload-content-evidence">
          <h3>Evidencia de arreglo</h3>
          <br />
          <img
            crossOrigin="anonymous"
            src={apiUrl + fixImage}
            alt={`Evidencia arreglo`}
          />
        </div>}
      </div>
      <div className="invitation-detail-actions">
        <div>
          <button className="btn-cancel" onClick={() => backAction()}>Volver</button>
          {invitation.status_id !== 8 && <button className="btn-accept" onClick={activateInvitation} >Aceptar</button>}
        </div>
      </div>
    </div>
  )
}