import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './DocIdTypeActions';

const initialState = {
  status: null,
  errormsg: null,
  loading: false,
  docIdTypeList: [],
}

const docIdTypeSlice = createSlice({
  name: "docIdType",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setdocIdTypeList(state, { payload }) {
      state.docIdTypeList = payload;
    }
  }
});

const docIdTypeActions = { ...docIdTypeSlice.actions, ...asyncActions }
const docIdTypeReducer = docIdTypeSlice.reducer

export { docIdTypeActions, docIdTypeReducer };