import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createLogger, logger } from 'redux-logger';

import rootSaga from "store/Sagas";
import rootReducers from 'store/Reducers';

let sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({ thunk: true, serializableCheck: false })
    .concat(sagaMiddleware)
    .concat(createLogger({
      collapsed: true,
      stateTransformer: state => state
    }))
];

const store = configureStore({
  reducer: rootReducers,
  middleware
});

sagaMiddleware.run(rootSaga);

export default store;
