import React from 'react';
import { useDispatch } from 'react-redux'
import { useForm } from "react-hook-form";

import { authActions } from 'services/Auth/AuthSlice';
import { FooterCopyright } from '../../components/FooterCopyright/FooterCopyright';
import { useHistory } from "react-router-dom";
import { SignUpForm } from '../../components/Signup/SignupForm/SignupForm';
import { Header } from 'components/layout/Header/Header';
export const SignUp = () => {
  let history = useHistory()
  const dispacth = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    /* console.log(data) */
    dispacth(authActions.signup(data))
  };

  return (
    <div className="login">
      <Header />
      <div className="login-content">
        <div className="login-signup login-register">
          <div className="login-sign">
            <h1 className="login-sign-user">REGÍSTRATE</h1>
            <p className="login-sign-yampi">Por favor completa la siguiente Información para registrarte en <span>YAMPI</span></p>
            <a href="/login" className="login_form__forgot_pass">¿Ya estás registrado?</a>
          </div>
        </div>
        <div className="login-row">
          <div className="login-column">
            <SignUpForm />
          </div>
        </div>
      </div>
      <FooterCopyright />
    </div>
  )
}

