import createAction from '../../common/utils/create-action';

const asyncActions = createAction({
  properties: [
    'getAll',
    'getInvitationsDescriptions',
    'activateInvitation',
    'registerInvite',
    'sendInvitation',
    'approve',
    'revoke'
  ]
})
export default asyncActions