import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'common/utils/use-query';
import { userActions } from 'services/User/UserSlice';
import { linkApp } from 'common/config/Environments';

export const ValidateEmail = () => {

  const dispatch = useDispatch()
  let query = useQuery();

  const { userValidate } = useSelector(state => state.user)

  useEffect(() => {
    if (query?.get("token"))
      dispatch(userActions.validateEmail({ token: query?.get("token") }))
  }, [query?.get("token")])


  return (
    <div className="validate-email root-grid">
      <h1>Validar correo</h1>
      <p>Necesitamos validar tu dirección de correo electrónico para que puedas seguir haciendo uso de los servicios de <span>Yampi</span></p>
      {userValidate && <a href={linkApp}>Ir a la app</a>}
    </div>
  )
}
