import createAction from '../../common/utils/create-action';

const asyncActions = createAction({
  properties: [
    'createContract',
    'getAllContracts',
    'updateDuration',
    "paymentStaggeredCreate",
    "getPaymentStaggered",
    "costsContract",
    "contractRenew",
    "contractNoRenew",
    "contractValidate",
    "getContractValidate",
    "contractPageBlank",
    "paymentStaggeredDelete",
    "getPaymentContract"
  ]
})
export default asyncActions