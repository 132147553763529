import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './InvitationActions';

const initialState = {
  status: null,
  loading: {
    approve: false
  },
  errormsg: null,
  invitations: [],
  descriptions: null
}

const invitationSlice = createSlice({
  name: "invitation",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setLoadinApprove(state, { payload }) {
      state.loading.approve = payload;
    },
    setInvitations(state, { payload }) {
      state.invitations = payload;
    },
    setDescriptions(state, { payload }) {
      state.descriptions = payload
    }
  }
});

const invitationActions = { ...invitationSlice.actions, ...asyncActions }
const invitationReducer = invitationSlice.reducer

export { invitationActions, invitationReducer };