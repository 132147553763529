import React from 'react';
import { useDispatch } from 'react-redux'
import { useForm } from "react-hook-form";
import { faUser, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { authActions } from 'services/Auth/AuthSlice';
import { InputForm } from 'components/form/input/InputForm';
import { useHistory } from 'react-router';

export const LoginForm = () => {
  let history = useHistory()
  const dispatch = useDispatch()
  const { register, handleSubmit, errors } = useForm()

  const onSubmit = data => {
    // console.log(data)
    dispatch(authActions.login(data))
    history.push("/")
  }

  return (
    <div className="login_form">
      <div className="login_form_welcome">
        <h3>Bienvenido a <strong>YAMPI</strong></h3>
        <h1>Iniciar sesión</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={errors.email ? "form-error-input" : ''}>
          <InputForm
            icon={faUser}
            name="email"
            refHook={register({ required: 'Campo obligatorio' })}
            placeholder="Correo"
            type="email"
          />
          {errors.email && <span className="form-error-msg">{errors.email.message}</span>}
        </div>
        <div className={errors.password ? "form-error-input" : ''}>
          <InputForm
            icon={faUnlock}
            name="password"
            refHook={register({ required: 'Campo obligatorio' })}
            placeholder="Contraseña"
            type="password"
          />
          {errors.password && <span className="form-error-msg">{errors.password.message}</span>}
        </div>
        <a className="login_form__forgot_pass">¿Olvidaste tu contraseña?</a>
        <button type="submit">INICIAR SESIÓN</button>
      </form>
    </div>
  )
}