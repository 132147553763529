import { apiUrl } from "common/config/Environments";
import { StorageService } from "common/storage/storage-service";

class Api {

  async post(endpoint, data) {
    const token = await StorageService.getItem('token');
    const response = await fetch(`${apiUrl}${endpoint}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    });
    const body = await response.json();
    if (response.status === 200) {
      return body;
    } else if (response.status === 405) {
      throw Error("Ha ocurrido un problema de comunicación con el servidor")
    } else {
      throw Error(JSON.stringify(body))
    }
  }

  async put(endpoint, data) {
    const token = await StorageService.getItem('token');
    const response = await fetch(`${apiUrl}${endpoint}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    });
    const body = await response.json();
    if (response.status === 200) {
      return body;
    } else if (response.status === 405) {
      throw Error("Ha ocurrido un problema de comunicación con el servidor")
    } else {
      throw Error(JSON.stringify(body))
    }
  }

  async patch(endpoint, data) {
    const token = await StorageService.getItem('token');
    endpoint = new URL(`${apiUrl}${endpoint}`);
    if (data)
      Object.keys(data).forEach(key => endpoint.searchParams.append(key, data[key]))
    return fetch(endpoint, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    })
      .then(response => {
        return response.json().then(body => {
          if (response.status === 200) {
            return body
          } else if (response.status === 405) {
            throw Error("Ha ocurrido un problema de comunicación con el servidor")
          } else {
            throw Error(JSON.stringify(body))
          }
        })
      })
  }

  async get(endpoint, data, tokenOptional) {
    const token = tokenOptional || await StorageService.getItem('token');
    endpoint = new URL(`${apiUrl}${endpoint}`);
    if (data)
      Object.keys(data).forEach(key => endpoint.searchParams.append(key, data[key]))
    return fetch(endpoint, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'BearerToken': token
      }
    })
      .then(response => {
        return response.json().then(body => {
          if (response.status === 200) {
            return body
          } else if (response.status === 405) {
            throw Error("Ha ocurrido un problema de comunicación con el servidor")
          } else {
            throw Error(JSON.stringify(body))
          }
        })
      })
  }

  async upload(endpoint, data) {
    const token = await StorageService.getItem('token');
    return fetch(`${apiUrl}${endpoint}`, {
      method: 'POST',
      body: data,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token
      }
    })
      .then(response => {
        return response.json().then(body => {
          if (response.status === 200) {
            return body
          } else if (response.status === 405) {
            throw Error("Ha ocurrido un problema de comunicación con el servidor")
          } else {
            throw Error(JSON.stringify(body))
          }
        })
      })
  }
}

export default new Api();