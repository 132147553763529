import { StorageService } from 'common/storage/storage-service';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { authActions } from 'services/Auth/AuthSlice';
import { bankActions } from 'services/Bank/BankSlice';
import { localizationActions } from 'services/Localization/LocalizationSlice';
import { phoneActions } from 'services/Phone/PhoneSlice';
import { specialityActions } from 'services/Speciality/SpecialitySlice';
import { Accounts } from './components/Steps/Accounts/Accounts';
import { Profile } from './components/Steps/Profile/Profile';
import { Speciality } from './components/Steps/Speciality/Speciality';
import { Steps } from './components/Steps/StepsTabs/Steps';

export const Specialist = () => {
  let history = useHistory()
  const dispatch = useDispatch()
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [specialistData, setSpecialistData] = useState({});
  const { currentUserData } = useSelector(state => state.auth)

  const nextStep = (data) => {
    let concatData = { ...specialistData, ...data };
    setSpecialistData(concatData);
    //let action = (currentStep === 3) ? history.push("/invitations") : setCurrentStep(currentStep + 1)

    if (currentStep === 1) {
      //Profile
      dispatch(authActions.profileUpdate(data.genre))
      dispatch(authActions.docidUpdate(data.docId))
      dispatch(phoneActions.registerPhone(data.phone))
      dispatch(localizationActions.saveLocalization(data.localization))
    }
    if (currentStep === 2) {
      //Specialities
      dispatch(specialityActions.registerSpecialities({ specialities: data.specialities, callback: (function () { setCurrentStep(currentStep + 1) }) }))
    }

    if (currentStep === 3) {
      //Accounts
      StorageService.setItem('spec', true)
      data.banks.map((account, i) =>
        dispatch(bankActions.addBankAccount(account))
      )
    }

    if (currentStep === 3) {
      history.push("/invitations")
    }
    if (currentStep === 1) {
      setCurrentStep(currentStep + 1);
    }
  }

  return (
    <div className="specialist">
      <div className="specialist-container">
        <div className="specialist-header">
          <p>Las mejores propiedades</p>
          <h2>Hola, {currentUserData?.profile.name}</h2>
        </div>
        <Steps currentStep={currentStep} />
        <div className="specialist-container-form">
          {currentStep === 1 && <Profile next={nextStep} />}
          {currentStep === 2 && <Speciality next={nextStep} />}
          {currentStep === 3 && <Accounts next={nextStep} />}
        </div>
      </div>
    </div >
  )
}