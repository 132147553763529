import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './GenreActions';

const initialState = {
  status: null,
  errormsg: null,
  loading: false,
  genreList: [],
}

const genreSlice = createSlice({
  name: "genre",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setGenres(state, { payload }) {
      state.genreList = payload;
    }
  }
});

const genreActions = { ...genreSlice.actions, ...asyncActions }
const genreReducer = genreSlice.reducer

export { genreActions, genreReducer };