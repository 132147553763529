import React from "react";
import "./FormReference.scss";

import { Footer } from "./Footer/Footer";
import { Form } from "./Form/Form";

export const FormReference = ({ location }) => {
  return (
    <main className="main">
      <div className="main-content">
        <section className="form-reference-benefits" />
        <div className="main-row">
          <div className="main-column">
            <Form location={location} />
          </div>
        </div>
      </div>

      <Footer />
    </main>
  );
};
