import createAction from '../../common/utils/create-action';

const asyncActions = createAction({
  speciality: [
    'getBanks',
    'getBanksAccountType',
    'addBankAccount',
    'getBankAccount'
  ]
})
export default asyncActions