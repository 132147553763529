import React, { useState } from 'react'
import ProgressBar from "@ramonak/react-progress-bar";
import { useForm } from 'react-hook-form';
import { formsActions } from 'services/Forms/FormsSlice';

export const FormSteps = ({ history }) => {

  const [isQuestions, setQuestions] = useState(true)
  const [isTab, setTab] = useState(false)
  const [isBackInUp, setBackInUp] = useState("animate__animated animate__backInUp")
  const [isBackInDown, setBackInDown] = useState("animate__animated animate__backOutUp")
  const [isCurrentQuestion, setCurrentQuestion] = useState(0)
  const [isData, setData] = useState()
  const { register, handleSubmit, errors } = useForm();

  const questionsPop = [
    {
      render: (
        <div>
          <h1> ¿En dónde se encuentra tu inmueble?: </h1>
          <button
            className={isData?.location == "Bogota" ? "selected" : ""}
            type="button"
            onClick={() => setData({ ...isData, location: "Bogota" })}
          >
            Bogotá y alrededores
          </button>

          <button
            className={isData?.location == "Medellin" ? "selected" : ""}
            type="button"
            onClick={() => setData({ ...isData, location: "Medellin" })}
          >
            Medellín y alrededores
          </button>

          <button
            className={isData?.location == "Armenia" ? "selected" : ""}
            type="button"
            onClick={() => setData({ ...isData, location: "Armenia" })}
          >
            Armenia y alrededores
          </button>
        </div>
      )
    },
    {
      render: (
        <div>
          <h1> ¿Cuánto es el valor de arriendo del inmueble?: </h1>
          <input
            name="cost"
            defaultValue={isData?.cost}
            ref={register({ required: "Campo obligatorio" })}
          />
          {errors?.cost && (
            <span className="form-error-msg">Campo obligatorio</span>
          )}
        </div>
      )
    },
    {
      render: (
        <div>
          <h1> ¿Qué tipo de inmueble es?:  </h1>
          <button
            className={isData?.typeProperty == "apartamento" ? "selected" : ""}
            type="button"
            onClick={() => setData({ ...isData, typeProperty: "apartamento" })}
          >
            Apartamento
          </button>

          <button
            className={isData?.typeProperty == "casa" ? "selected" : ""}
            type="button"
            onClick={() => setData({ ...isData, typeProperty: "casa" })}
          >
            Casa
          </button>
        </div>
      )
    },
    {
      render: (
        <div>
          <h1> Digita la dirección de tu inmueble </h1>
          <input
            name="addressProperty"
            defaultValue={isData?.addressProperty}
            ref={register({ required: "Campo obligatorio" })}
          />
          {errors?.addressProperty && (
            <span className="form-error-msg">Campo obligatorio</span>
          )}
        </div>
      )
    },
    {
      render: (
        <div>
          <h1> ¿Ahora bríndanos tus datos para poderte brindar asesoría en tu inmueble  </h1>

          <label> Nombres y apellidos </label>
          <input
            name="name"
            defaultValue={isData?.name}
            ref={register({ required: "Campo obligatorio" })}
          />
          {errors?.name && (
            <span className="form-error-msg">Campo obligatorio</span>
          )}

          <label> Correo electrónico </label>

          <input
            name="email"
            defaultValue={isData?.email}
            ref={register({ required: "Campo obligatorio" })}
          />
          {errors?.email && (
            <span className="form-error-msg">Campo obligatorio</span>
          )}

          <label> Teléfono </label>
          <input
            name="phone"
            defaultValue={isData?.phone}
            ref={register({ required: "Campo obligatorio" })}
          />
          {errors?.phone && (
            <span className="form-error-msg">Campo obligatorio</span>
          )}
        </div>
      )
    },
  ]

  const questionsInq = [
    {
      render: (
        <div>
          <h1> ¿En dónde buscas tu inmueble?: </h1>
          <button
            className={isData?.location == "Bogota" ? "active" : ""}
            type="button"
            onClick={() => setData({ ...isData, location: "Bogota" })}
          >
            Bogotá y alrededores
          </button>

          <button
            className={isData?.location == "Medellin" ? "active" : ""}
            type="button"
            onClick={() => setData({ ...isData, location: "Medellin" })}
          >
            Medellín y alrededores
          </button>

          <button
            className={isData?.location == "Armenia" ? "active" : ""}
            type="button"
            onClick={() => setData({ ...isData, location: "Armenia" })}
          >
            Armenia y alrededores
          </button>
        </div>
      )
    },
    {
      render: (
        <div>
          <h1> ¿Cuánto es el valor de arriendo máximo que pagarías por un arriendo?: </h1>
          <input
            name="cost"
            defaultValue={isData?.cost}
            ref={register({ required: "Campo obligatorio" })}
          />
          {errors?.cost && (
            <span className="form-error-msg">Campo obligatorio</span>
          )}
        </div>
      )
    },
    {
      render: (
        <div>
          <h1> ¿Qué tipo de inmueble es?:  </h1>
          <button
            className={isData?.typeProperty == "apartamento" ? "active" : ""}
            type="button"
            onClick={() => setData({ ...isData, typeProperty: "apartamento" })}
          >
            Apartamento
          </button>

          <button
            className={isData?.typeProperty == "casa" ? "active" : ""}
            type="button"
            onClick={() => setData({ ...isData, typeProperty: "casa" })}
          >
            Casa
          </button>
        </div>
      )
    },
    {
      render: (
        <div>
          <h1> Para cuantas personas buscas el inmueble </h1>
          <input
            name="quantityPeople"
            defaultValue={isData?.quantityPeople}
            ref={register({ required: "Campo obligatorio" })}
          />
          {errors?.quantityPeople && (
            <span className="form-error-msg">Campo obligatorio</span>
          )}
        </div>
      )
    },
    {
      render: (
        <div>
          <h1> ¿Ahora bríndanos tus datos para poderte brindar asesoría en tu inmueble  </h1>

          <label> Nombres y apellidos </label>

          <input
            name="name"
            defaultValue={isData?.name}
            ref={register({ required: "Campo obligatorio" })}
          />
          {errors?.name && (
            <span className="form-error-msg">Campo obligatorio</span>
          )}

          <label> Correo electrónico </label>

          <input
            name="email"
            defaultValue={isData?.email}
            ref={register({ required: "Campo obligatorio" })}
          />
          {errors?.email && (
            <span className="form-error-msg">Campo obligatorio</span>
          )}

          <label> Teléfono </label>

          <input
            name="phone"
            defaultValue={isData?.phone}
            ref={register({ required: "Campo obligatorio" })}
          />
          {errors?.phone && (
            <span className="form-error-msg">Campo obligatorio</span>
          )}
        </div>
      )
    },
  ]

  const nextQuestion = (data) => {

    if (isCurrentQuestion == questionsPop?.length - 1) {

      const contact = {
        "contact": {
          "first_name": data?.name,
          // "last_name": data?.lastName,
          "email": data?.email,
          "mobile_number": data?.phone,
          "tags": [data?.info]
        }
      }

      succes(contact)

      // dispatch(formsActions.createContact({
      //   data: contact,
      //   callback: () => succes(contact)
      // }))

    } else {
      setQuestions(false)
      setTimeout(() => {
        setQuestions(true)
        setData({ ...isData, ...data })
        setCurrentQuestion(isCurrentQuestion + 1)
      }, 500);
    }
  }

  const succes = (data) => {
    history.push("/descargas");
  }

  const backQuestion = () => {
    setQuestions(false)
    setTimeout(() => {
      setQuestions(true)
      setCurrentQuestion(isCurrentQuestion - 1)
    }, 500);
  }

  const changeTab = (key) => {
    setData(undefined)
    setCurrentQuestion(0)
    setTab(key)
  }

  const disabledButton = !isTab || (
    isTab == "pop" ?
      ((isCurrentQuestion == 0 && !isData?.location) || (isCurrentQuestion == 2 && !isData?.typeProperty))
      :
      ((isCurrentQuestion == 0 && !isData?.location) || (isCurrentQuestion == 2 && !isData?.typeProperty))
  )

  return (
    <form onSubmit={handleSubmit((data) => nextQuestion(data))} className="form-step">
      <div className="form-step-header">
        <a href={'/'} >
          <img src={require('assets/logo.png')} alt='logo' />
        </a>

        <div style={{ marginTop: 20, alignItems: "center" }} >
          <h2>¡Bienvenid@ a la mejor</h2>
          <h1>experiencia de arrendamiento!</h1>
        </div>
      </div>

      <div className="form-step-quest">
        <div className="form-step-quest-img">
          <img src={require('assets/Imagen-App.png')} />
        </div>

        {!isTab &&
          <div
            className={isQuestions ? isBackInUp : isBackInDown}
          >
            <h1>Estoy interesad@ en ser:</h1>
            <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
              <button onClick={() => changeTab("pop")} > Propietari@ </button>
              <button onClick={() => changeTab("inq")} > Inquilin@ </button>
            </div>
          </div>
        }

        {isTab &&
          <div className={isQuestions ? isBackInUp : isBackInDown}>
            {isTab == "pop" ?
              questionsPop?.find((question, index) => index == isCurrentQuestion)?.render
              :
              questionsInq?.find((question, index) => index == isCurrentQuestion)?.render
            }
          </div>
        }

      </div>

      <div className="form-step-actions">
        <button
          type="button"
          disabled={!isTab || (isCurrentQuestion == 0)}
          onClick={backQuestion}
        >
          Anterior
        </button>

        <div>
          <ProgressBar
            height={13}
            completed={
              isTab == "pop" ?
                isCurrentQuestion * 100 / questionsPop?.length :
                isCurrentQuestion * 100 / questionsPop?.length
            }
            isLabelVisible={false}
          />
        </div>

        <button
          type="submit"
          disabled={disabledButton}
        >
          Siguiente
        </button>
      </div>
      <div className="form-step-actions form-step-actions-responsive">

        <div>
          <ProgressBar
            height={13}
            completed={
              isTab == "pop" ?
                isCurrentQuestion * 100 / questionsPop?.length :
                isCurrentQuestion * 100 / questionsPop?.length
            }
            isLabelVisible={false}
          />
        </div>

        <div className="form-step-actions-responsive-btns">
          <button
            type="button"
            disabled={!isTab || (isCurrentQuestion == 0)}
            onClick={backQuestion}
          >
            Anterior
        </button>

          <button
            type="submit"
            disabled={disabledButton}
          >
            Siguiente
        </button>
        </div>
      </div>
    </form>
  )
}
