import React from "react";
import "./Form.scss";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  faUser,
  faEnvelope,
  faPhone,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";

import { useHistory } from "react-router-dom";
import { InputCenter } from "components/form/input-center/InputCenter";
import { formsActions } from "services/Forms/FormsSlice";

export const Form = ({ location }) => {

  let history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.forms)
  const { register, handleSubmit, errors } = useForm();
  const override = `
  display: block;
  margin: 0 auto;
  border-color: white;
`;

  const onSubmit = (data) => {
    const contact = {
      "contact": {
        "first_name": data?.userName,
        "last_name": data?.lastName,
        "email": data?.email,
        "mobile_number": data?.phone,
        "tags": ["PROP"]
      }
    }

    dispatch(formsActions.createContact({
      data: contact,
      callback: succes
    }))
  };

  const succes = () => {
    history.push("/descargas");
  }

  return (
    <div className="form-reference_form">
      <h1>Referencia al propietario de tu inmueble</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={errors.address ? "form-error-input" : ""}>
          <InputCenter
            icon={faUser}
            name="userName"
            id="userName"
            refHook={register({ required: "Campo obligatorio" })}
            placeholder="Nombres"
            type="text"
          />
          {errors.userName && (
            <span className="form-error-msg">{errors.userName.message}</span>
          )}
        </div>

        <div className={errors.lastName ? "form-error-input" : ""}>
          <InputCenter
            icon={faUserAlt}
            name="lastName"
            id="lastName"
            refHook={register({ required: "Campo obligatorio" })}
            placeholder="Apellidos"
            type="text"
          />
          {errors.lastName && (
            <span className="form-error-msg">{errors.lastName.message}</span>
          )}
        </div>

        <div className={errors.email ? "form-error-input" : ""}>
          <InputCenter
            icon={faEnvelope}
            name="email"
            id="email"
            refHook={register({ required: "Campo obligatorio" })}
            placeholder="Correo"
            type="email"
          />
          {errors.email && (
            <span className="form-error-msg">{errors.email.message}</span>
          )}
        </div>

        <div className={errors.phone ? "form-error-input" : ""}>
          <InputCenter
            icon={faPhone}
            name="phone"
            id="phone"
            refHook={register({ required: "Campo obligatorio" })}
            placeholder="Celular"
            type="phone"
          />
          {errors.phone && (
            <span className="form-error-msg">{errors.phone.message}</span>
          )}
        </div>
        <button type="submit">
          {loading?.createContact ?
            <ClipLoader
              color={"#ffffff"}
              loading={true}
              css={override}
              size={30}
            />
            :
            "Registrate"
          }
        </button>
      </form>
    </div>
  );
};
