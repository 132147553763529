import { put, all, takeLatest } from 'redux-saga/effects';
import { mediaActions } from './MediaSlice';
import ApiService from '../../common/api/Api';

function* uploadMedia({ payload }) {
  let formData = new FormData();
  formData.append('group', payload.group);
  formData.append('parent_id', payload.parentId);
  formData.append("media", payload.media);
  if (payload.ticketVerify)
    formData.append('ticket_verify', payload.ticketVerify);
  if (payload.name)
    formData.append('name', payload.name);

  try {

    yield put(mediaActions.onChangeStatus('loading'))
    const response = yield ApiService.upload(`media`, formData)
    yield put(mediaActions.onChangeStatus('success'))

    payload.callback && payload.callback()
  } catch (err) {
    console.error(err)
    yield put(mediaActions.onError('No se pudo cargar el archivo'))
  }
}

function* getMedia({ payload }) {
  try {
    yield put(mediaActions.onChangeStatus('loading'))
    const response = yield ApiService.get(`/media/${payload.entityId}/${payload.entityName}`)
    if (response) {
      yield put(mediaActions.setMedia(response))
    }
    yield put(mediaActions.onChangeStatus('success'))
  } catch (err) {
    yield put(mediaActions.onError('No se puede listar los archivos'))
  }
}

function* deleteMedia({ payload }) {
  try {
    yield put(mediaActions.setLoading({ key: "deleteImage", newState: true }))
    const response = yield ApiService.delete(`/media/${payload.mediaId}`)
    payload.callback && payload.callback(payload?.index)
  } catch (err) {
    yield put(mediaActions.onError('No se puede listar los archivos'))
  }
  yield put(mediaActions.setLoading({ key: "deleteImage", newState: false }))
}

function* ActionWatcher() {
  yield takeLatest(mediaActions.uploadMedia, uploadMedia)
  yield takeLatest(mediaActions.getMedia, getMedia)
  yield takeLatest(mediaActions.getMedia, getMedia)
  yield takeLatest(mediaActions.deleteMedia, deleteMedia)
}

export default function* MediaSaga() {
  yield all([
    ActionWatcher(),
  ]);
}


