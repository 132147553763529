import React from "react";
import "../Benefits/Benefits.scss";

import pencil from "../../../../../assets/icons/pencil.svg";
import card from "../../../../../assets/icons/card.svg";
import phone from "../../../../../assets/icons/phone.svg";
import files from "../../../../../assets/icons/files.svg";
import check from "../../../../../assets/icons/check.svg";
import inmueble from "../../../../../assets/inmueble.jpg";

export const Benefits = () => {
  return (
    <main className="form-property-benefits">
      <div className="form-property-benefits__image">
        <img src={inmueble} alt="Sofas" />
      </div>
      <section className="form-property-benefits__lists">
        <ul>
          <li>
            <h3>Beneficios para propietarios</h3>
          </li>
          <li>
            <h3>Beneficios para inquilinos</h3>
          </li>
        </ul>
        <ul>
          <li>
            <img src={pencil} alt="Lapiz" />
            <p>Firma un contrato digital.</p>
          </li>
          <li>
            <img src={files} alt="Tajeta de credito" />
            <p>
              Firma tu contrato de arriendo desde el celular, sin papeleo ni
              citas.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <img src={card} alt="Lapiz" />
            <p>Recibe todos los pagos directamente a tu cuenta.</p>
          </li>
          <li>
            <img src={check} alt="Tajeta de credito" />
            <p>
              Realiza el trámite de la póliza de arriendo fácilmente desde tu
              celular.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <img src={phone} alt="Lapiz" />
            <p>Mantén toda la información de tu arriendo en tu celular.</p>
          </li>
          <li>
            <img src={phone} alt="Tajeta de credito" />
            <p>Tendrás toda tu información en un solo lugar.</p>
          </li>
        </ul>
      </section>
    </main>
  );
};
