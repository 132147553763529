import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './AppActions';

const initialState = {
  feedbackShowing: {
    isShowing: false,
    feedbackTitle: '',
    feedbackDescription: '',
    feedbackIcon: ''
  }
}

const appSlice = createSlice({
  name: "feedback",
  initialState: initialState,
  reducers: {
    setFeedback(state, { payload }) {
      state.feedbackShowing = { ...state.feedbackShowing, ...payload };
    }
  }
});

const appActions = { ...appSlice.actions, ...asyncActions }
const appReducer = appSlice.reducer

export { appActions, appReducer };