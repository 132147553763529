import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './PhoneActions';

const initialState = {
  status: null,
  errormsg: null,
}

const phoneSlice = createSlice({
  name: "phone",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    }
  }
});

const phoneActions = { ...phoneSlice.actions, ...asyncActions }
const phoneReducer = phoneSlice.reducer

export { phoneActions, phoneReducer };