import { put, all, takeLatest } from 'redux-saga/effects';
/* import { Alert } from 'react-native'; */
import decode from 'jwt-decode';
import ApiService from '../../common/api/Api';
import { StorageService } from '../../common/storage/storage-service';
import { genreActions } from './GenreSlice';

function* getGenres({ payload }) {
  yield put(genreActions.onChangeStatus('loading'))
  try {
    const genres = yield ApiService.get('/genre', payload)
    yield put(genreActions.setGenres(genres))
    yield put(genreActions.onChangeStatus('success'))
  } catch (err) {
    yield put(genreActions.onError(err.toString()))
  }
}

function* ActionWatcher() {
  yield takeLatest(genreActions.getGenres, getGenres)
}

export default function* GenreSaga() {
  yield all([
    ActionWatcher(),
  ]);
}
