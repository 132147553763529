import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './ScheduleActions';

const initialState = {
  status: null,
  errormsg: null,
  schedule: [],
  scheduleTicket: [],
  currentSchedules: []
}

const scheduleSlice = createSlice({
  name: "schedule",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setSchedule(state, { payload }) {
      state.schedule = payload;
    },
    setScheduleTicket(state, { payload }) {
      state.scheduleTicket = payload;
    },
    setSCurrentSchedules(state, { payload }) {
      state.currentSchedules = payload;
    }
  }
});

const scheduleActions = { ...scheduleSlice.actions, ...asyncActions }
const scheduleReducer = scheduleSlice.reducer

export { scheduleActions, scheduleReducer };