export const env_production = process.env.NODE_ENV === "production";
export const corssUrl = 'https://yampi-corss.herokuapp.com/';
/* export const apiUrl = process.env.REACT_APP_API_URL; */


/* Stage */
/* export const apiUrl = corssUrl + 'https://api-stage.yampi.co/'; */

/* Production */
export const apiUrl = corssUrl + 'https://api.yampi.co/';

/* Developer */
/* export const apiUrl = corssUrl + 'https://testing.yampi.co/'; */

/* SocketUrl */
export const socketUrl = process.env.REACT_APP_SOCKET_URL;

/* Redes sociales */
export const siteUrl = process.env.REACT_APP_SITE_URL;
export const instagram = "https://www.instagram.com/yampi.co/";
export const facebook = "https://www.facebook.com/YampiInmobiliaria";
export const yampiApp = "https://play.google.com/store/apps/details?id=co.yampi.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";


/* link app */

/* Producción */
export const linkApp = "yampi://home";

/* Desarrollo */
// export const linkApp = "exp://192.168.20.20:19000/--/home";

// exp://192.168.20.20:19000/--/home?email=darian7cc@gmail.com&&password=pok01qdK

// yampi://home?email=darian7cc@gmail.com&&password=pok01qdK
