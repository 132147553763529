import React from "react";
import "../Footer/Footer.scss";

import facebook from "../../../../assets/icons/facebook.svg";
import instagram from "../../../../assets/icons/instagram.svg";
import yampi from "../../../../assets/icons/yampi.svg";
import { facebook as facebokRedirect, instagram as instagramRedirect, yampiApp } from "../../../../common/config/Environments";

const redirect = (key) => {
  window.location.href = key;
}

export const Footer = () => {
  return (
    <footer className="landing-footer">
      <div className="landing-footer__text">
        <p>Copyright © 2020, Developed by Yampi</p>
      </div>
      <div className="landing-footer__icons">
        <img style={{ cursor: "pointer" }} onClick={() => redirect(facebokRedirect)} src={facebook} alt="Icono de facebook" />
        <img style={{ cursor: "pointer" }} onClick={() => redirect(instagramRedirect)} src={instagram} alt="Icono de instagram" />
        <img style={{ cursor: "pointer" }} onClick={() => redirect(yampiApp)} src={yampi} alt="Icono de yampi" />
      </div>
    </footer>
  );
};
