import React, { useEffect, useState } from 'react';
import { getMonthName, getDayName, expiredDate } from 'common/utils/dates';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { invitationActions } from 'services/Invitation/InvitationSlice';
import { scheduleActions } from 'services/Schedule/ScheduleSlice';
import { ticketActions } from 'services/Ticket/TicketSlice';
import { useForm } from 'react-hook-form';
import { InputForm } from 'components/form/input/InputForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCalendarAlt, faCommentDollar, faMoneyCheckAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { mediaActions } from 'services/Media/MediaSlice';
import { StorageService } from 'common/storage/storage-service';
import ImageUploader from "react-images-upload";


export const FormTicketCost = ({ isInvitation,
  succesCreateFile,
  setBufferFile,
  file }) => {
  const { register, handleSubmit, errors } = useForm()


  const [nameFile, setNameFile] = useState('')
  //const [bufferFile, setBufferFile] = useState();
  const [upload, setUpload] = useState(false);
  const [uploadError, showUploadError] = useState(false);
  const [uploadErrorMsg, setUploadErrorMsg] = useState('');
  const { loading: loadingTicket } = useSelector((state) => state.ticket)
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    //if (upload) {
    const payload = [
      {
        ticket_id: isInvitation?.invitable_id,
        cost_type: { id: data.costType }, //valor al inquilino(7) o al dueño(11)
        currency: { id: 2 },
        value: data.ticketCost,
      },
    ]
    dispatch(ticketActions.addTicketCost({ data: payload, callback: succesCreateFile() }));
    //} else {
    //  showUploadError(true)
    //}
  }

  const onDrop = picture => {
    setBufferFile(picture[0]);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">

        <div className={errors.ticketCost ? "form-error-input" : ''}>
          <InputForm
            icon={faMoneyCheckAlt}
            name="ticketCost"
            type="number"
            placeholder="Costo del servicio"
            refHook={register({ required: 'Campo obligatorio' })}
          />
          {errors.ticketCost && <span className="form-error-msg">{errors.ticketCost.message}</span>}
        </div>
        <div className="form-price-diagnostic">
          <h3>Diagnóstico del daño</h3>
          <div>
            <div>
              <span>Desgaste</span>
              <input name="costType" type="radio" value={11} ref={register({ required: 'Campo obligatorio' })} />
            </div>
            <div>
              <span>Mal uso</span>
              <input name="costType" type="radio" value={7} ref={register({ required: 'Campo obligatorio' })} />
            </div>
          </div>
          {errors.costType && <span className="form-error-msg">{errors.costType.message}</span>}
        </div>
      </div>

      <div className="upload-content">
        <h3>Adjunte una evidencia del servicio:</h3>
        <ImageUploader
          withIcon={true}
          onChange={onDrop}
          singleImage={true}
          label="Archivo máximo de 5mb, solo jpg, png, gif"
          fileSizeError="El peso de la imagen es mayor a 5mb"
          fileTypeError="EL tipo de archivo no es válido"
          buttonText="Subir evidencia"
          withPreview={true}
          imgExtension={[".jpg", ".gif", ".png"]}
          maxFileSize={5242880} />

      </div>
      <input type="submit" />
    </form>
  )
}