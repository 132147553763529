import React from 'react';
import "./Footer.scss"

import facebook from "../../../../../assets/icons/facebook.svg"
import instagram from "../../../../../assets/icons/instagram.svg"
import yampi from "../../../../../assets/icons/yampi.svg"
import { facebook as facebokRedirect, instagram as instagramRedirect, yampiApp } from "../../../../../common/config/Environments";


export const Footer = () => {

  const redirect = (key) => {
    window.location.href = key;
  }

  return (
    <footer className="footer">
      <div className="footer__text">
        <p>Copyright © 2020, Developed by Yampi</p>
      </div>
      <div className="footer__icons">
        <img style={{ cursor: "pointer" }} onClick={() => redirect(facebokRedirect)}  src={facebook} alt="Icono de facebook"/>
        <img style={{ cursor: "pointer" }} onClick={() => redirect(instagramRedirect)} src={instagram} alt="Icono de instagram"/>
        <img style={{ cursor: "pointer" }} onClick={() => redirect(yampiApp)} src={yampi} src={yampi} alt="Icono de yampi"/>
      </div>
    </footer>
  )
}