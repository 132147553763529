export const TenantFormHtml = `<!-- <link href="https://d1gwclp1pmzk26.cloudfront.net/agile/forms/v1/agile-form.min.css" rel="stylesheet"> -->
<link href="https://doxhze3l6s7v9.cloudfront.net/app/static/agile/forms/agile-form.min.css" rel="stylesheet">
<form class="form-view theme2 form-tenant" id="agile-form" action="https://yampi.agilecrm.com/formsubmit" style="max-width:450px;" method="GET">
<fieldset>
<!-- Form Name -->
<div style="display: none; height: 0px; width: 0px;">
<input type="hidden" id="_agile_form_name" name="_agile_form_name" value="Déjanos encontrar el inmueble de tus sueños">
<input type="hidden" id="_agile_domain" name="_agile_domain" value="yampi">
<input type="hidden" id="_agile_api" name="_agile_api" value="5a1svl3050ndltgmnqg3vuhdeh">
<input type="hidden" id="_agile_redirect_url" name="_agile_redirect_url" value="https://app.yampi.co/descargas">
<input type="hidden" id="_agile_document_url" name="_agile_document_url" value="">
<input type="hidden" id="_agile_confirmation_msg" name="_agile_confirmation_msg" value="">
<input type="hidden" id="_agile_form_id_tags" name="tags" value="BUSCA">
<input type="hidden" id="_agile_form_id" name="_agile_form_id" value="6531557947932672">
</div>
<!-- Text input-->
<div class="agile-group">
  <label class="agile-label" for="Name">Nombres</label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="Name" name="first_name" type="text" placeholder="* Nombres" class="agile-height-default" required>
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group">
  <label class="agile-label" for="Apellidos">Apellidos</label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="Apellidos" name="last_name" type="text" placeholder="* Apellidos" class="agile-height-default" required>
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group">
  <label class="agile-label" for="Correo electrónico">Correo electrónico</label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="Correo electrónico" name="email" type="email" placeholder="* Correo electrónico" class="agile-height-default" required>
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group">
  <label class="agile-label" for="Celular">Celular</label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="Celular" name="phone" type="text" placeholder="Celular" class="agile-height-default">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Select Basic -->
<div class="agile-group">
  <label class="agile-label" for="Tipo de inmueble">Tipo de inmueble<span class="agile-span-asterisk"> *</span></label>
  <div class="agile-field-xlarge agile-field">
    <select id="Tipo de inmueble" name="Tipo de inmueble" class="agile-height-default" required="">
    	<option value="" disabled selected>* Tipo de inmueble</option>
    	<option value="Casa">Casa</option>
    	<option value="Apartamento">Apartamento</option>
    </select>
  </div>
</div>
<!-- Text input-->
<div class="agile-group required-control">
  <label class="agile-label" for="Número de alcobas">Número de alcobas<span class="agile-span-asterisk"> *</span></label>
  <div class="agile-field-xlarge agile-field">
	<input id="Número de alcobas" name="Numero de alcobas" type="number" placeholder="* Número de alcobas" class="agile-height-default" required="">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group">
  <label class="agile-label" for="Cantidad de parqueaderos">Cantidad de parqueaderos</label>
  <div class="agile-field-xlarge agile-field">
	<input id="Cantidad de parqueaderos" name="Numero de parqueos" type="number" placeholder="Cantidad de parqueaderos" class="agile-height-default">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group required-control">
  <label class="agile-label" for="Presupuesto estimado">Presupuesto estimado<span class="agile-span-asterisk"> *</span></label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="Presupuesto estimado" name="Presupuesto estimado" type="text" placeholder="* Presupuesto estimado" class="agile-height-default" required="">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group required-control">
  <label class="agile-label" for="Ciudad">Ciudad<span class="agile-span-asterisk"> *</span></label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="Ciudad" name="city" type="text" placeholder="* Ciudad" class="agile-height-default" required="">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!-- Text input-->
<div class="agile-group required-control">
  <label class="agile-label" for="Barrio">Barrio<span class="agile-span-asterisk"> *</span></label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="Barrio" name="address" type="text" placeholder="* Barrio" class="agile-height-default" required="">
  </div>
  <div class="agile-custom-clear"></div>
</div>
<!--recaptcha aglignment-->
<!-- Button -->
<div class="agile-group">
  <label class="agile-label">&nbsp;</label>
  <div class="agile-field agile-button-field">
    <button type="submit" class="agile-button">Guardar</button>
    <br><span id="agile-error-msg"></span>
  </div>
</div>
</fieldset>
</form>
<script type="text/javascript">
(function(a){var b=a.onload,p=true;isCaptcha=false;if(p){a.onload="function"!=typeof b?function(){try{_agile_load_form_fields()}catch(a){}}:function(){b();try{_agile_load_form_fields()}catch(a){}}};var formLen=document.forms.length;for(i=0;i<formLen;i++){if(document.forms.item(i).getAttribute("id")== "agile-form"){a.document.forms.item(i).onsubmit=function(a){a.preventDefault();try{_agile_synch_form_v5(this)}catch(b){this.submit()}}}}})(window);
</script>
`