import React from 'react';
import "../Main/Main.scss"
import { Footer } from '../Footer/Footer';
import { Benefits } from '../Benefits/Benefits';
import { Form } from '../Form/Form';

export const Main = () => {


  return (
    <main className="main">

      <div className="main-content">
        <Benefits />
        <div className="main-row">
          <div className="main-column">
            <Form />
          </div>
        </div>
      </div>

      <Footer />
    </main>
  )
}