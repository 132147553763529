import { put, all, takeLatest, select } from 'redux-saga/effects';
import decode from 'jwt-decode';
import { authActions } from './AuthSlice';
import { appActions } from '../App/AppSlice';
import ApiService from '../../common/api/Api';
import { StorageService } from '../../common/storage/storage-service';

function* login({ payload }) {
  yield put(authActions.onChangeStatus('loading'))
  try {
    const response = yield ApiService.post('login/email', {
      email: payload?.email,
      password: payload?.password
    })

    console.log("response login:", response);

    try {
      StorageService.setItem('token', response.token)
      yield put(authActions.setToken(response.token))
      yield put(authActions.fetchUser())

      payload.callback && payload.callback()
    }
    catch (err) {
      console.log("segundo login:", err);
      return err
    }


  } catch (err) {
    console.log("primer login:", err);
    let erroObj = JSON.parse(err.message);

    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: erroObj.message == 'Email or password fail!' ? 'Comprueba tu email o contraseña' : 'Estamos teniendo problemas, reintenta mas tarde',
      feedbackIcon: ''
    }))

    yield put(authActions.onError(err.toString()))
  }
}

function* socialLogin({ payload }) {
  yield put(authActions.onChangeStatus('loading'))
  try {
    const response = yield ApiService.post('register/social', payload)
    try {
      StorageService.setItem('token', response.token)
      yield put(authActions.setToken(response.token))
      yield put(authActions.fetchUser())
    }
    catch (err) {
      return TypeError(err)
    }
  } catch (err) {
    yield put(authActions.onError(err.toString()))
  }
}

function* checkIsAuth() {
  try {
    const token = yield StorageService.getItem('token');

    if (token == null)
      yield put(authActions.setIsAuthenticated(false))

    const role = yield StorageService.getItem('role');
    try {
      let { exp } = decode(token);
      if (Date.now() <= exp * 1000) {
        yield put(authActions.setToken(token))
        yield put(authActions.setIsAuthenticated(true))

      } else {
        yield put(authActions.setIsAuthenticated(false))
        return TypeError('El token ha expirado');
      }

      yield put(authActions.fetchUser())
      yield put(authActions.setRole({ role: role }))
    }
    catch (e) {
      yield put(authActions.setIsAuthenticated(false))
      return e;
    }
  }
  catch (err) {
    yield put(authActions.onError(err.toString()))
  }
}

function* fetchUser({ payload }) {
  yield put(authActions.onChangeStatus('loading'))
  try {
    const user = yield ApiService.get('/user-data')
    const docid = yield ApiService.get(`/docid/${user.id}/user`)
    const localization = yield ApiService.get(`/localization/${user.id}/user`)
    const phone = yield ApiService.get(`/phone/${user.id}/user`)
    const userInfo = yield ApiService.get(`/user-info`)

    user.docid = docid;
    user.localization = localization;
    user.phone = phone;
    user.userInfo = userInfo;

    const tokenExpo = yield select((state) => state.auth.tokenExpo);
    if (tokenExpo)
      yield ApiService.post('notification/token/update', { token: tokenExpo })

    yield put(authActions.setUserData(user))
    yield put(authActions.onChangeStatus('success'))

    payload?.callback && payload.callback()

  } catch (err) {
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: 'Estamos teniendo problemas, reintenta mas tarde',
      feedbackIcon: ''
    }))
    yield put(authActions.onError(err.toString()))
  }
}

function* signup({ payload }) {
  const { email, password } = payload;
  payload['profile']['country_id'] = 2;
  yield put(authActions.onChangeStatus('loading'))
  try {
    const response = yield ApiService.post('register/email', payload);
    yield put(authActions.onChangeStatus('success'))
    yield put(authActions.login({ email, password }))
    if (response)
      yield put(authActions.signupResponse())
  } catch (err) {
    yield put(authActions.signupResponse())
    /* yield put(authActions.onError(err.message)) */
    let erroObj = JSON.parse(err?.message);
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ooops!',
      feedbackDescription: erroObj.data?.email ? 'Tu correo ya se encuentra registrado' : 'Estamos teniendo problemas reintenta mas tarde',
      feedbackIcon: ''
    }))
  }
}

function* setRole({ payload }) {
  StorageService.setItem('role', payload.role)
  payload.callback && payload.callback()
}

function* logout() {
  yield StorageService.clear()
}

function* profileUpdate({ payload }) {
  try {
    //const response = yield ApiService.request(`profile`, 'PUT', payload)
    const response = yield ApiService.put(`profile`, payload)
    if (response) {
      yield put(authActions.fetchUser())
      yield put(appActions.setFeedback({
        isShowing: true,
        feedbackTitle: 'Completado',
        feedbackDescription: 'Tus datos fueron actualizados',
        feedbackIcon: ''
      }))
      yield put(authActions.profileUpdateResponse())
    }
  } catch (err) {
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: 'Ocurrio un problema, por favor intenta mas tarde',
      feedbackIcon: ''
    }))
  }
}

function* docidUpdate({ payload }) {
  try {
    const response = yield ApiService.put(`docid`, payload)
    yield put(authActions.fetchUser())

    if (response?.id == 0)
      yield put(appActions.setFeedback({
        isShowing: true,
        feedbackTitle: 'Ups!',
        feedbackDescription: 'El número de identificación ya se encuentra registrado utilizar otro número.',
        feedbackIcon: ''
      }))

  } catch (err) {
    console.log("err docidUpdate:", err);
  }
}

function* recoveryPassword({ payload }) {
  try {
    const response = yield ApiService.post('password/recover', payload)
    console.log("response recovery:", response);
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Completado',
      feedbackDescription: 'Te enviaremos un email para restablecer tu contraseña.',
      feedbackIcon: ''
    }))
  } catch (err) {
    let erroObj = JSON.parse(err.message);
    console.log("err recovery:", err);
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ups!',
      feedbackDescription: erroObj.data?.email ? 'Tu correo no se encuentra registrado' : 'Te enviaremos un email para restablecer tu contraseña.',
      feedbackIcon: ''
    }))
  }
}

function* changePassword({ payload }) {
  try {
    const response = yield ApiService.post('password/change', { password: payload.password })
    if (response) {

      payload.callback && payload.callback()

      yield put(appActions.setFeedback({
        isShowing: true,
        feedbackTitle: 'Completado',
        feedbackDescription: 'Tu contraseña se cambio exitosamente',
        feedbackIcon: ''
      }))
      yield put(authActions.changePasswordResponse())
    }

  } catch (err) {
    /* let erroObj = JSON.parse(err.message); */
    yield put(authActions.changePasswordResponse())
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Ooops!',
      feedbackDescription: 'Lo sentimos ocurrio un error, reintenta mas tarde',
      feedbackIcon: ''
    }))
  }

}

function* ActionWatcher() {
  yield takeLatest(authActions.login, login)
  yield takeLatest(authActions.socialLogin, socialLogin)
  yield takeLatest(authActions.checkIsAuth, checkIsAuth)
  yield takeLatest(authActions.fetchUser, fetchUser)
  yield takeLatest(authActions.signup, signup)
  yield takeLatest(authActions.logout, logout)
  yield takeLatest(authActions.setRole, setRole)
  yield takeLatest(authActions.recoveryPassword, recoveryPassword)
  yield takeLatest(authActions.profileUpdate, profileUpdate)
  yield takeLatest(authActions.docidUpdate, docidUpdate)
  yield takeLatest(authActions.changePassword, changePassword)
}

export default function* AuthSaga() {
  yield all([
    ActionWatcher(),
  ]);
}
