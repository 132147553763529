import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appActions } from 'services/App/AppSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faUnlock } from '@fortawesome/free-solid-svg-icons'

export default function Feedback() {

  const [visible, hideFeedback] = useState(false);
  const { feedbackShowing, } = useSelector(state => state.app)
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(appActions.setFeedback({
      isShowing: false,
      feedbackTitle: '',
      feedbackDescription: '',
      feedbackIcon: ''
    }))
    hideFeedback(false)
  }

  useEffect(() => {
    if (feedbackShowing.feedbackDescription) {
      hideFeedback(true)
      setTimeout(function () { hideFeedback(false) }, 5000);
    }
  }, [feedbackShowing])


  return (
    visible && <div className="feedback">
      <div className="feedback-close" onClick={() => onSubmit()}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className="feedback-content">
        <h2>{feedbackShowing.feedbackTitle}</h2>
        <p>{feedbackShowing.feedbackDescription}</p>
      </div>
    </div>
  )
}