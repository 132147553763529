import React, { useEffect, useState } from 'react';

import { Header } from 'components/layout/Header/Header'
import { FooterCopyright } from 'scenes/Public/components/FooterCopyright/FooterCopyright'
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'services/Auth/AuthSlice';
import { OwnerFormHtml } from '../CrmForms/OwnerForm.js';
import { TenantFormHtml } from '../CrmForms/TenantForm.js';
import { ReferFormHtml } from '../CrmForms/ReferForm.js';
import { ReferedTenantFormHtml } from '../CrmForms/ReferedTenantForm';

export const SignUpUsers = () => {
  const [currentForm, setCurrentForm] = useState('')
  const [titleForm, setTitleForm] = useState('¡Bienvenid@ a la mejor experiencia de arrendamiento!')
  const [subtitleForm, setSubtitleForm] = useState('Déjanos tus datos')
  let location = useLocation()

  const { token } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(authActions.checkIsAuth())
  }, [])

  useEffect(() => {
    //let html = OwnerFormHtml    
    if (location?.pathname === '/registrate') {
      setCurrentForm(OwnerFormHtml)
    }
    if (location?.pathname === '/registrate/inquilino') {
      setCurrentForm(TenantFormHtml)
      setTitleForm('Déjanos encontrar el inmueble de tus sueños')
      setSubtitleForm('Diligencia la siguiente información')
    }
    if (location?.pathname === '/registrate/referencias') {
      setTitleForm('Refiere a tu propietario')
      setSubtitleForm('La mejor experiencia de arriendo empieza aquí')
      setCurrentForm(ReferFormHtml)
    }
    if (location?.pathname === '/registrate/inquilino_referido') {
      setCurrentForm(ReferedTenantFormHtml)
      setTitleForm('Formato Prospecto Inquilino')
      setSubtitleForm('Formato para que el equipo comercial remita interesados en los inmuebles')
    }
  }, [])

  return (
    <div className="login sign-up-user-form">
      {!token && <Header />}
      <div className="login-content">
        <div className="sign-up-cover">
          {(location?.pathname === '/registrate') &&
            <div className="sign-up-cover-message">
              <div>
                <h2>Administra tu propiedad con Yampi desde tu&nbsp;
             <a href="/descargas" target="_blank">celular</a>
                </h2>
                <div>
                  <p>¿Cómo puedo arrendar de forma fácil?</p>
                  <p>¡Es muy fácil!</p>
                  <ul>
                    <li>Firma el contrato de forma digital</li>
                    <li>Adquiere tu póliza de forma dígital en 24 horas</li>
                    <li>Realiza el inventario de tu inmueble</li>
                    <li>Accede al historial de tu inmueble en cualquier momento</li>
                    <li>Recibe los pagos directamente en tu cuenta bancaria</li>
                  </ul>
                  <h2>¡Maximiza tus ingresos rentando ya!</h2>
                </div>
              </div>
            </div>
          }
        </div>
        <div className="sign-up-users">
          <div className="login-sign">
            <h1 className="login-sign-user">{titleForm}</h1>
            <p className="login-sign-yampi">{subtitleForm}</p>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: currentForm
            }} />
          {/* <object type="text/html" data={currentForm}
            style={{ width: '100%', height: '100%' }} /> */}
        </div>
      </div>
      <FooterCopyright />
    </div>
  )
}

