import { combineReducers } from '@reduxjs/toolkit'
import { appReducer } from 'services/App/AppSlice';
import { authReducer } from 'services/Auth/AuthSlice';
import { bankReducer } from 'services/Bank/BankSlice';
import { docIdTypeReducer } from 'services/DocIdType/DocIdTypeSlice';
import { formsReducer } from 'services/Forms/FormsSlice';
import { genreReducer } from 'services/Genre/GenreSlice';
import { invitationReducer } from 'services/Invitation/InvitationSlice';
import { localizationReducer } from 'services/Localization/LocalizationSlice';
import { locationReducer } from 'services/Location/LocationSlice';
import { mediaReducer } from 'services/Media/MediaSlice';
import { phoneReducer } from 'services/Phone/PhoneSlice';
import { scheduleReducer } from 'services/Schedule/ScheduleSlice';
import { shoppingReducer } from 'services/Shopping/ShoppingSlice';
import { specialityReducer } from 'services/Speciality/SpecialitySlice';
import { ticketReducer } from 'services/Ticket/TicketSlice';
import { userReducer } from 'services/User/UserSlice';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  location: locationReducer,
  genre: genreReducer,
  docIdType: docIdTypeReducer,
  speciality: specialityReducer,
  invitation: invitationReducer,
  localization: localizationReducer,
  schedule: scheduleReducer,
  ticket: ticketReducer,
  user: userReducer,
  bank: bankReducer,
  phone: phoneReducer,
  media: mediaReducer,
  shopping: shoppingReducer,
  forms: formsReducer,
})

export default rootReducer