import React from "react";
import "../Benefits/Benefits.scss";

import pencil from "../../../../assets/icons/pencil.svg";
import card from "../../../../assets/icons/card.svg";
import phone from "../../../../assets/icons/phone.svg";
import files from "../../../../assets/icons/files.svg";
import check from "../../../../assets/icons/check.svg";
import clock from "../../../../assets/icons/clock.svg";
import main from "../../../../assets/main-landing.png";
import next from "../../../../assets/icons/next.svg";

export const Benefits = () => {
  return (
    <main className="landing-benefits">
      <section className="landing-benefits__main">
        <div>
          <h1>
            La manera más fácil y rentable de administrar tu inmueble finalmente
            ha llegado.
          </h1>
          <button type="submit">
            Ir a la página
            <img src={next} alt="siguiente" />
          </button>
        </div>
        <img src={main} alt="celular con app de yampi" />
      </section>
      <section className="landing-benefits__list">
        <ul>
          <li>
            <img src={pencil} alt="Lapiz" />
            <p>Firma un contrato digital.</p>
          </li>
          <li>
            <img src={card} alt="Tajeta de credito" />
            <p>Recibe todos los pagos directamente a tu cuenta.</p>
          </li>
        </ul>
        <ul>
          <li>
            <img src={phone} alt="Lapiz" />
            <p>
              Realiza un inventario digital en tiempo real para ser firmado por
              el inquilino.
            </p>
          </li>
          <li>
            <img src={files} alt="Tajeta de credito" />
            <p>Adquiere tu póliza de manera digital en 24 horas sin papeleo.</p>
          </li>
        </ul>
        <ul>
          <li>
            <img src={check} alt="Lapiz" />
            <p>Firma un contrato digital.</p>
          </li>
          <li>
            <img src={clock} alt="Tajeta de credito" />
            <p>
              Si tu arrendatario no paga cumplido, automáticamente esto afectará
              su historial.
            </p>
          </li>
        </ul>
        <div className="landing-benefits__list__rate">
          <h1>UNA TARIFA. TODO INCLUIDO.</h1>
          <i>
            Medio canon por colocación y el 4% mensual por administración. Sin
            costo adicional para tu inquilino.
          </i>
        </div>
      </section>
    </main>
  );
};
