import React from 'react';
import { useDispatch } from 'react-redux'
import { useForm, useWatch } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUnlock, faEnvelope, faEnvelopeOpen, faUserAlt, faUnlockAlt } from '@fortawesome/free-solid-svg-icons'
import { authActions } from 'services/Auth/AuthSlice';
import { useHistory } from "react-router-dom";
import { InputForm } from 'components/form/input/InputForm';
import { FooterCopyright } from '../FooterCopyright/FooterCopyright';
import { Header } from 'components/layout/Header/Header';

export const RecoverPassword = () => {
  let history = useHistory()
  const dispatch = useDispatch()

  const { register, handleSubmit, errors, watch } = useForm()

  const onSubmit = data => {
    const userData = {
      "email": data.email,
      "password": data.password,
      "role_id": 5,
      "profile": {
        "name": data.userName,
        "lastname": data.lastName,
        "country_id": 2
      }
    }

    dispatch(authActions.signup(userData))
    history.push("/")
  }

  return (
    <div className="recover-password">
      <div className="recover-password-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={errors.email ? "form-error-input" : ''}>
            <InputForm
              icon={faUnlock}
              name="password"
              refHook={register({ required: 'Campo obligatorio' })}
              placeholder="Contraseña"
              type="password"
            />
            {errors.email && <span className="form-error-msg">{errors.email.message}</span>}
          </div>

          <div className={errors.confirmEmail ? "form-error-input" : ''}>
            <InputForm
              icon={faUnlockAlt}
              name="confirmPassword"
              refHook={register({
                required: 'Campo obligatorio',
                validate: (value) => value === watch('password') || "las contraseñas no coinciden"
              })}
              placeholder="Confirma la nueva contraseña"
              type="password"
            />
            {errors.confirmEmail && <span className="form-error-msg">{errors.confirmEmail.message}</span>}
          </div>
          <button type="submit">Recuperar contraseña</button>
        </form>
      </div>
      <FooterCopyright />
    </div>
  )
}