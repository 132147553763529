import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './LocationActions';

const initialState = {
  status: null,
  errormsg: null,
  loading: {
    getLocation: false,
  },
  locations: [],
  countries: [],
}

const locationSlice = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setLocations(state, { payload }) {
      state.locations = payload;
    },
    setCountries(state, { payload }) {
      state.countries = payload;
    }
  }
});

const locationActions = { ...locationSlice.actions, ...asyncActions }
const locationReducer = locationSlice.reducer

export { locationActions, locationReducer };