import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './ContractActions';

const initialState = {
  status: null,
  errormsg: null,
  contracts: [],
  contractValidate: [],
  lastContract: null,
  finale: null,
  paymentStaggered: null,
  paymentContract: null,
  loading: {
    finale: false,
    paymentStaggeredCreate: false,
    getPaymentStaggered: false,
    costsContract: false,
    contractRenew: false,
    contractNoRenew: false,
    contractValidate: false,
    getContractValidate: false,
    contractPageBlank: false,
    paymentStaggeredDelete: false,
    getPaymentContract: false
  }
}

const contractSlice = createSlice({
  name: "contract",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setContracts(state, { payload }) {
      state.contracts = payload;
    },
    setLastContract(state, { payload }) {
      state.lastContract = payload;
    },
    finale(state, { payload }) {
      state.finale = undefined;
    },
    setFinale(state, { payload }) {
      state.finale = payload;
    },
    setPaymentStaggered(state, { payload }) {
      state.paymentStaggered = payload;
    },
    setContractValidate(state, { payload }) {
      state.contractValidate = payload;
    },
    setPaymentContract(state, { payload }) {
      state.paymentContract = payload;
    },
    setLoading(state, { payload: { key, newState } }) {
      state.loading = {
        ...state.loading,
        [key]: newState
      };
    }

  }
});

const contractActions = { ...contractSlice.actions, ...asyncActions }
const contractReducer = contractSlice.reducer

export { contractActions, contractReducer };