import { put, all, takeLatest } from 'redux-saga/effects';
import ApiService from '../../common/api/Api';
import { userActions } from './UserSlice';
import { appActions } from '../App/AppSlice';

function* completeness({ payload }) {
  yield put(userActions.onChangeStatus('loading'))
  try {
    const userInfo = yield ApiService.get('/user-info', payload)
    yield put(userActions.setUserInfo(userInfo))
    yield put(userActions.onChangeStatus('success'))
  } catch (err) {
    yield put(userActions.onError(err.toString()))
  }
}

function* validateEmail({ payload }) {
  yield put(userActions.setLoading({ key: "validateEmail", newState: true }))

  try {
    const response = yield ApiService.get('/user-val-email', undefined, undefined, payload.token)
    yield put(userActions.setUserValidate(true))

    payload.callback && payload.callback()
  } catch (err) {
    yield put(userActions.onError(err.toString()))
  }

  yield put(userActions.setLoading({ key: "validateEmail", newState: false }))
}

function* sendEmailValidate({ payload }) {
  yield put(userActions.setLoading({ key: "sendEmailValidate", newState: true }))

  try {
    const resposne = yield ApiService.requestSignal(`email/send-email`, "POST", payload.data)
    yield put(appActions.setFeedback({
      isShowing: true,
      feedbackTitle: 'Validar correo!',
      feedbackDescription: 'Revisa la bandeja de tu correo y valida el mismo',
      feedbackIcon: ''
    }))
  } catch (err) {
    yield put(userActions.onError(err.toString()))
  }

  yield put(userActions.setLoading({ key: "sendEmailValidate", newState: false }))
}

function* setMetadata({ payload }) {
  yield put(userActions.setLoading({ key: "setMetadata", newState: true }))

  try {
    const resposne = yield ApiService.post(`/update-metadata`, payload.data)
  } catch (err) {
    yield put(userActions.onError(err.toString()))
  }

  yield put(userActions.setLoading({ key: "setMetadata", newState: false }))
}

function* ActionWatcher() {
  yield takeLatest(userActions.completeness, completeness)
  yield takeLatest(userActions.validateEmail, validateEmail)
  yield takeLatest(userActions.sendEmailValidate, sendEmailValidate)
  yield takeLatest(userActions.setMetadata, setMetadata)
}

export default function* UserSaga() {
  yield all([
    ActionWatcher(),
  ]);
}
