import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlock } from "@fortawesome/free-solid-svg-icons";

export const InputCenter = ({
  refHook,
  name,
  placeholder,
  type,
  icon,
  defaultValue,
  value,
  disabled,
  id
}) => {
  return (
    <div className="form-item-center">
      <FontAwesomeIcon icon={icon || faUnlock} />
      <input
        name={name}
        placeholder={placeholder}
        type={type}
        defaultValue={defaultValue}
        ref={refHook}
        value={value}
        disabled={disabled}
        onChange={() => {}}
        id={id}
      />
    </div>
  );
};
