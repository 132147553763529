import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './FormsActions';

const initialState = {
  status: null,
  errormsg: null,
  loading: {
    createContact: false
  },
  genreList: [],
}

const forms = createSlice({
  name: "genre",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setGenres(state, { payload }) {
      state.genreList = payload;
    },
    setLoading(state, { payload: { key, newState } }) {
      state.loading = { ...state.loading, [key]: newState }
    }
  }
});

const formsActions = { ...forms.actions, ...asyncActions }
const formsReducer = forms.reducer

export { formsActions, formsReducer };