import createAction from '../../common/utils/create-action';

const asyncActions = createAction({
  speciality: [
    'getSpecialities',
    'getSubSpecialities',
    'registerSpecialities',
    'getUserSpecialities',
    'getUserSpeciality',
    'updateSpeciality',
    'deleteSpeciality',
    'createSpeciality'
  ]
})
export default asyncActions