import { fork, all } from 'redux-saga/effects';

import AuthSaga from 'services/Auth/AuthSaga';
import BankSaga from 'services/Bank/BankSaga';
import DocIdTypeSaga from 'services/DocIdType/DocIdTypeSaga';
import FormsSaga from 'services/Forms/FormsSaga';
import GenreSaga from 'services/Genre/GenreSaga';
import InvitationSaga from 'services/Invitation/InvitationSaga';
import LocalizationSaga from 'services/Localization/LocalizationSaga';
import LocationSaga from 'services/Location/LocationSaga';
import MediaSaga from 'services/Media/MediaSaga';
import PhoneSaga from 'services/Phone/PhoneSaga';
import ScheduleSaga from 'services/Schedule/ScheduleSaga';
import ShoppingSaga from 'services/Shopping/ShoppingSaga';
import SpecialitySaga from 'services/Speciality/SpecialitySaga';
import TicketSaga from 'services/Ticket/TicketSaga';
import UserSaga from 'services/User/UserSaga';

export default function* rootSaga() {
    yield all([
        fork(AuthSaga),
        fork(LocationSaga),
        fork(GenreSaga),
        fork(DocIdTypeSaga),
        fork(SpecialitySaga),
        fork(LocalizationSaga),
        fork(InvitationSaga),
        fork(ScheduleSaga),
        fork(ShoppingSaga),
        fork(TicketSaga),
        fork(MediaSaga),
        fork(PhoneSaga),
        fork(UserSaga),
        fork(BankSaga),
        fork(FormsSaga),
    ]);
}