import React, { useEffect, useState } from 'react';
import { ModalDialog } from 'components/Modals/Dialog/Dialog';
import { getMonthName } from 'common/utils/dates';
import { Modal } from 'components/Modals/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { invitationActions } from 'services/Invitation/InvitationSlice';
import { shoppingActions } from 'services/Shopping/ShoppingSlice';
import { Informative } from './components/Informative/Informative';
import { InvitationDetail } from './components/InvitationDetail/InvitationDetail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';

export const Invitation = () => {
  const [invitationsList, setInvitationsList] = useState([])
  const [openTab, setOpenTab] = useState('active')
  const [totalAmount, setTotalAmount] = useState(0)
  const [selectedInvitation, setSelectedInvitation] = useState()
  const [openModal, toggleModal] = useState(false)

  const dispatch = useDispatch()
  const { invitations, descriptions, status } = useSelector(state => state.invitation)
  const { carServices } = useSelector(state => state.shopping)

  useEffect(() => {
    getInvitations()
  }, [])

  useEffect(() => {
    const providerInvitations = invitations.filter(t => t.invitation_type_id === 5)
    dispatch(invitationActions.getInvitationsDescriptions(providerInvitations))
    let invList = {
      actives: providerInvitations.filter(t => t.status_id === 8), //in_progress
      pending: providerInvitations.filter(t => t.status_id === 1), //open
      closed: providerInvitations.filter(t => t.status_id === 6) //closed
    }
    setInvitationsList(invList)
  }, [invitations])

  useEffect(() => {
    let total = 0
    if (carServices.length > 0) carServices.map(t => total = total + t.data[0].total_amount)
    setTotalAmount(total)
  }, [carServices])

  const seeInvitationDetail = (invitation) => {
    let invi = { ...invitation, description: descriptions?.filter(t => t.id === invitation.id)[0]?.description }
    setSelectedInvitation(invi)
    toggleModal(!openModal)
  }

  const getInvitations = () => {
    dispatch(invitationActions.getAll())
    dispatch(shoppingActions.getShopping())
  }

  return (
    <div className="invitation">
      <div className="invitation-container">
        <div className="invitation-content">
          <div className="invitation-component">
            <div className="invitation-title">
              <span>Solicitudes</span>
            </div>
            <Informative invitations={invitationsList} totalAmount={totalAmount} />
          </div>
          <div className="invitation-body">
            <div className="invitation-flex-column">
              <div className="invitation-flex-content">
                <div className="invitation-flex-title" >
                  <span>Últimas solicitudes</span>
                </div>
                <div className="invitation-flex-row mt-4">
                  <div>
                    <h3>ID solicitud</h3>
                  </div>
                  <div>
                    <h3>Fecha</h3>
                  </div>
                  <div>
                    <h3>Descripción</h3>
                  </div>
                  <div className="reload-btn" onClick={() => getInvitations()}>
                    <FontAwesomeIcon icon={faRedoAlt} />
                  </div>
                </div>
                <div className="invitation-flex-list">
                  {status !== "loading" ?
                    invitationsList?.pending?.length === 0 ?
                      <h2>No hay solicitudes pendientes</h2> :
                      invitationsList?.pending?.map((item, i) =>
                        <div key={i} className="invitation-flex-card">
                          <div>
                            <h6>{item.id}</h6>
                          </div>
                          <div>
                            <h6>{getMonthName(item.created_at)} {item.created_at.substring(8, 10)}</h6>
                          </div>
                          <div>
                            <h6>{descriptions?.filter(t => t.id === item.id)[0]?.description?.body || "Sin descripción"}</h6>
                          </div>
                          <button onClick={() => seeInvitationDetail(item)} className="invitation-flex-btn_acepte">Detalles</button>
                        </div>
                      )
                    :
                    <h2>Obteniendo solicitudes...</h2>
                  }
                </div>
              </div>
            </div>
            <div className="invitation-flex-request">
              <div className="invitation-flex-content">
                <div className="invitation-flex-title" >
                  <span>Solicitudes en curso</span>
                </div>
                <div className="invitation-flex-status">
                  <div onClick={() => setOpenTab('active')} className={`${openTab === 'active' ? 'tab-active' : ''}`}>
                    <h3>Activas</h3>
                  </div>
                  <div onClick={() => setOpenTab('closed')} className={`${openTab === 'closed' ? 'tab-active' : ''}`}>
                    <h3>Cerradas</h3>
                  </div>
                </div>
                <div className="invitation-flex-row">
                  <div>
                    <h3>ID solicitud</h3>
                  </div>
                  <div>
                    <h3>Fecha</h3>
                  </div>
                  <div>
                    <h3>Descripción</h3>
                  </div>
                </div>
                <div className="invitation-flex-list">
                  {
                    (openTab === 'active' ? invitationsList?.actives : invitationsList?.closed)?.length === 0 ?
                      <div className="invitation-flex-square" >
                        <div className="invitation-flex-text__title">
                          <span>No hay solicitudes en curso</span>
                        </div>
                        <div className="invitation-flex-text">
                          <div >
                            <h4>No te preocupes, te avisaremos el estado de las solicitudes que aceptes</h4>
                          </div>
                        </div>
                      </div>
                      :
                      (openTab === 'active' ? invitationsList?.actives : invitationsList?.closed)?.map((item, i) =>
                        <div key={i} className="invitation-flex-card" onClick={() => seeInvitationDetail(item)}>
                          <div>
                            <h6>{item.id}</h6>
                          </div>
                          <div>
                            <h6>{getMonthName(item.created_at)} {item.created_at.substring(8, 10)}</h6>
                          </div>
                          <div>
                            <h6>{descriptions?.filter(t => t.id === item.id)[0]?.description?.body || "Sin descripción"}</h6>
                          </div>
                        </div>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && <Modal visible={openModal}
        modal={<InvitationDetail invitation={selectedInvitation} backAction={toggleModal} />}
        hideModal={toggleModal} />}
    </div>
  )
}