import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './MediaActions';

const initialState = {
  status: null,
  errormsg: null,
  media: null,
  loading: {
    deleteImage: false
  }
}

const mediaSlice = createSlice({
  name: "media",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setMedia(state, { payload }) {
      state.media = payload
    },
    setLoading(state, { payload: { key, newState }  }) {
      state.loading = { ...state.loading, [key]: newState } 
    }
  }
});

const mediaActions = { ...mediaSlice.actions, ...asyncActions }
const mediaReducer = mediaSlice.reducer

export { mediaActions, mediaReducer };