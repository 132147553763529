import {
  faCity,
  faEnvelope,
  faMoneyBillAlt,
} from "@fortawesome/free-solid-svg-icons";
import { InputCenter } from "components/form/input-center/InputCenter";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { authActions } from "services/Auth/AuthSlice";
import "./Form.scss";

export const Form = ({ location }) => {

  let history = useHistory();
  const dispatch = useDispatch();
  const [upperPrice, setUpperPrice] = useState(10000000);
  const [lowerPrice, setLowePrice] = useState(500000);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {

    const userData = {
      city: data.city,
      characteristics: data.characteristics,
    };

    dispatch(authActions.signup(userData));
    history.push("/descargas");
  };

  return (
    <div className="form-property_form">
      <h1>¡Cuéntanos sobre el inmueble que buscas!</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={errors.email ? "form-error-input" : ""}>
          <InputCenter
            icon={faEnvelope}
            name="email"
            id="email"
            refHook={register({ required: "Campo obligatorio" })}
            placeholder="Correo"
            type="email"
            defaultValue={location?.state?.email}
            disabled={location?.state?.email}
          />
          {errors.email && (
            <span className="form-error-msg">{errors.email.message}</span>
          )}
        </div>
        <div className={errors.address ? "form-error-input" : ""}>
          <InputCenter
            icon={faCity}
            name="city"
            id="city"
            refHook={register({ required: "Campo obligatorio" })}
            placeholder="Ciudad"
            type="text"
          />
          {errors.city && (
            <span className="form-error-msg">{errors.city.message}</span>
          )}
        </div>
        <div className="form-slider">
          <h3>Rango de precios</h3>
          <p>500.000 COP - 10'000.000 COP</p>

          <div className="multi-range-container">
            <div className="multi-range">
              <input
                className="range"
                type="range"
                min="500000"
                max="9900000"
                onChange={() => {}}
                step="100000"
                id="lower"
                name="lower"
                onInput={(e) => {
                  if (+e.target.value > +upperPrice - 100000)
                    setUpperPrice(+upperPrice + 100000);

                  setLowePrice(+e.target.value);
                }}
                value={lowerPrice}
              />
              <span id="range-color" className="range-color"></span>
              <input
                className="range"
                type="range"
                min="600000"
                max="10000000"
                onChange={() => {}}
                step="100000"
                id="upper"
                id="upper"
                value={upperPrice}
                onInput={(e) => {
                  if (+e.target.value < +lowerPrice + 100000)
                    setLowePrice(+lowerPrice - 100000);
                  setUpperPrice(+e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={errors.price ? "form-error-input" : ""}>
          <InputCenter
            icon={faMoneyBillAlt}
            name="price"
            id="price"
            refHook={register({ required: "Campo obligatorio" })}
            placeholder="Precio"
            type="text"
            value={`${lowerPrice} - ${upperPrice}`}
          />
          {errors.price && (
            <span className="form-error-msg">{errors.price.message}</span>
          )}
        </div>

        <div className={errors.characteristics ? "form-error-input" : ""}>
          <div className="form-item-center form-item-center-text-area">
            <textarea
              name="characteristics"
              id="characteristics"
              placeholder="Características"
              rows="4"
              cols="35"
              ref={register({ required: "Campo obligatorio" })}
            />
          </div>
          {errors.characteristics && (
            <span className="form-error-msg">
              {errors.characteristics.message}
            </span>
          )}
        </div>

        <button type="submit">Envíar</button>
      </form>
    </div>
  );
};
