import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './ShoppingActions';

const initialState = {
  status: null,
  errormsg: null,
  loading: false,
  carServices: []
}

const shoppingSlice = createSlice({
  name: "shopping",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setCarServices(state, { payload }) {
      state.carServices = payload;
    }
  }
});

const shoppingActions = { ...shoppingSlice.actions, ...asyncActions }
const shoppingReducer = shoppingSlice.reducer

export { shoppingActions, shoppingReducer };