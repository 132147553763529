import { createSlice } from '@reduxjs/toolkit';
import asyncActions from './UserActions';

const initialState = {
  status: null,
  errormsg: null,
  userInfo: null,
  loading: {
    sendEmailValidate: false,
    setMetadata: false
  },
  userValidate: false
}

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setUserInfo(state, { payload }) {
      state.userInfo = payload;
    },
    setUserValidate(state, { payload }) {
      state.userValidate = payload;
    },
    setLoading(state, { payload: { key, newState } }) {
      state.loading = { ...state.loading, [key]: newState };
    },
  }
});

const userActions = { ...userSlice.actions, ...asyncActions }
const userReducer = userSlice.reducer

export { userActions, userReducer };